import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  open: boolean;
  setOpen: any;
  refresh: any;
  selectedArrayId: any;
  setSelected: any;
  wordMenu: any;
  pd: any
}

const DeleteArrayAll: React.FC<DeleteModalProps> = ({
  open,
  setOpen,
  refresh,
  selectedArrayId,
  setSelected,
  wordMenu,
  pd
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = useState(false);
  const handleClose = () => setOpen(false);

  const handleDeleteAndClose = async () => {
    setActive(true);
    try {
      const deletePromises = selectedArrayId.map((id: any) => {
        if (pd === 'equipment') {
          return axios.delete(`/equipment/${id}`);
        } else if (pd === 'report') {
          return axios.post(`time-reports/deleteReports/${id}`);
        } else if (pd === 'user') {
          return axios.post(`deleteUser/${id}`);
        } else if (pd === 'expenses') {
          return axios.delete(`expenses/${id}`);
        }
        return null;
      }).filter((promise: any) => promise !== null); 
      await Promise.all(deletePromises);
      enqueueSnackbar('Delete successfully', { variant: 'success' });
      setSelected([]);
    } catch (error: any) {
      if (error.response?.status === 500) {
        enqueueSnackbar('Unable to delete, already in use', { variant: 'error' });
      } else {
        console.error('Error', error);
        enqueueSnackbar('Error', { variant: 'error' });
      }
    } finally {
      setActive(false);
      handleClose();
      refresh();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="text-center">
              {`${t('AreYouSure')} ${selectedArrayId.length} ${t(wordMenu)}?`}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              className="flex justify-between items-center gap-4">
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                disabled={active}
                onClick={handleDeleteAndClose}>
                {active ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('Yes')
                )}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                disabled={active}
                onClick={handleClose}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DeleteArrayAll;
