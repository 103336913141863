import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import enGB from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from "react-datepicker";
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { transformToHierarchy } from '../util/tree';
import TreeViewComponent from '../util/TreeViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';


registerLocale('en-GB', enGB);

interface TemporaryDrawerProps {
  setShowModalAdd: any,
  is24HourFormat: any
  refreshExpenses: any,
}

export default function AddEquipment({ setShowModalAdd, is24HourFormat, refreshExpenses }: TemporaryDrawerProps) {
  const [startDate, setStartDate] = useState(new Date());
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [prevSelectedCategoryId, setPrevSelectedCategoryId] = useState<number[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const [time, setTime] = useState(moment().hour(9).minute(0).toDate());
  const [department, setDepartment] = useState('');
  const [project, setProject] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const handleClose = () => setShowModalAdd(false);
  const [active, setActive] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleTimeFromChange = (time: Date) => {
    setTime(time);
  };

  const handleChangeAcrive = () => {
    setActive(!active);
  }

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
  };

  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    if (isCategoryListVisible) {
      setSelectedCategoryId(prevSelectedCategoryId)
    }
    setPrevSelectedCategoryId(selectedCategoryId);
    event.stopPropagation();
  };

  const handleClickAddCategoryId = (selectedId: number) => {
    const selectedCategory = allCategory?.find(node => node.id === selectedId);
    if (selectedCategory) {
      setCategoryName(selectedCategory.name)
      setCategoryListVisible(false);
    } else {
      setCategoryName(['All category'])
      console.log('Category not found');
      setCategoryListVisible(false);
    }
  };

  const handleExpenses = async () => {
    try {
      if (selectedCategoryId.length === 0) {
        enqueueSnackbar('Choose Category', { variant: 'error' });
        return
      }

      if (!active) {
        if (!name.trim() || !phone.trim()) {
          enqueueSnackbar('Enter name and phone', { variant: 'error' });
          return;
        }
      }

      if (!amount.trim()) {
        enqueueSnackbar('Choose Amount', { variant: 'error' });
        return
      }

      if (/[.,]$/.test(amount)) {
        enqueueSnackbar('Amount cannot end with a dot or comma', { variant: 'error' });
        return;
      }

      const fullDateTime = moment(startDate).set({
        hour: moment(time, 'HH:mm').hour(),
        minute: moment(time, 'HH:mm').minute(),
        second: 0
      });

      let payload: any = {
        date: fullDateTime,
        nominal_department: department,
        staff: active,
        description: description,
        project: project,
        categoryId: Number(selectedCategoryId[0]),
        status: 'pending',
        amount: Number(amount),
      };

      if (!active) {
        payload = {
          ...payload,
          name,
          contact_number: phone,
        };
      }

      await axios.post(`expenses/add`, payload);

      handleClose();
      refreshExpenses();
      enqueueSnackbar('Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error creating', { variant: 'error' });
      console.error('Error creating:', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: categoryData } = await axios.get('category');
        setAllCategory(categoryData)
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
      } catch (e) {
      }
    })();
  }, []);

  return (
    <Box sx={{
      width: 420,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' id='reg-user-edit'>
          <div className='flex justify-end'>
            <h1 className='text-[20px] text-left font-bold'>{t('AddExpenses')}</h1>
          </div>
          <div className='flex flex-col justify-evenly flex-grow' >
            <hr className='hr mt-[10px]' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('Information')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Date')}</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => handleDateChange(date)}
                  className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Time')}</label>
                <DatePicker
                  selected={time}
                  onChange={(time: any) => handleTimeFromChange(time)}
                  className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                  placeholderText="Select Time"
                  showTimeSelect
                  dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  showTimeSelectOnly
                  timeIntervals={15}
                />
              </div>
            </div>
            <div className='flex gap-[5px] mt-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Department')}</label>
                <input type="text"
                  value={department}
                  className="w-full rounded-[5px] edit-input"
                  onChange={(e: any) => setDepartment(e.target.value)}
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Project')}</label>
                <input type="text"
                  value={project}
                  className="w-full rounded-[5px] edit-input"
                  onChange={(e: any) => setProject(e.target.value)}
                />
              </div>
            </div>
            <div className='flex gap-[5px] mt-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Category')}</label>
                <div className="timesheets-category-box w-full bg-[var(--bg-input)] text-[var(--text-color)] relative text-[16px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
                  onClick={handleClickCategoryBox}>
                  <div className="timesheets-category-context width-fix">{categoryName.length ? categoryName : 'ChooseCategory'}</div>
                  <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50' /></div>
                  {isCategoryListVisible && (
                    <div className="timesheets-category-list pb-0 w-full absolute top-[35px] right-0 z-[999] py-[5px]" onClick={handleCategoryListClick}>
                      <div className='fix-height mb-[10px]'>
                        <TreeViewComponent
                          nodes={category}
                          onNodeSelect={handleNodeSelect}
                          updateTree={''}
                          openCheckModal={openCheckModal}
                          selectedCategoryId={selectedCategoryId} />
                      </div>
                      <div className="py-[5px] px-[5px] flex justify-end bg-fix"><button className='bg-[var(--btn-user-btn)] text-[var(--text-color)] border-none text-[18px] py-[5px] px-[15px] cursor-pointer rounded-[5px] hover:bg-[var(--btn-user-hover)]'
                        onClick={() => handleClickAddCategoryId(Number(selectedCategoryId[0]))}>select</button></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='flex gap-[5px] mt-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Description')}</label>
                <textarea
                  value={description}
                  className="w-full rounded-[5px] edit-input resize-none text-[16px]"
                  rows={2}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <hr className='hr mt-1 mb-[5px]' />
            <div className='flex mt-[10px] items-center'>
              <div className='w-full pl-[10px]'>
                <FormControlLabel control={<Switch
                  checked={active}
                  onChange={handleChangeAcrive}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label={t('Staff')} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Amount')}</label>
                <input type="text"
                  value={amount}
                  className="w-full rounded-[5px] edit-input text-right"
                  onChange={(e: any) => {
                    let value = e.target.value;
                    value = value.replace(/[^0-9.,]/g, '');
                    if ((value.match(/[.,]/g) || []).length > 1) {
                      value = value.slice(0, -1);
                    }
                    setAmount(value);
                  }}
                />
              </div>
            </div>
            {!active &&
              <>
                <hr className='hr mt-1 mb-[5px]' />
                <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('AddUserInfo')}</h2>
                <div className='flex gap-[5px] mt-[5px]'>
                  <div className='flex flex-col w-full'>
                    <label className='text-[10px] ml-[5px]'>{t('Name')}</label>
                    <input type="text"
                      value={name}
                      className="w-full rounded-[5px] edit-input text-right"
                      onChange={(e: any) => setName(e.target.value)}
                    />
                  </div>
                  <div className='flex flex-col w-full'>
                    <label className='text-[10px] ml-[5px]'>{t('Phone')}</label>
                    <input type="text"
                      value={phone}
                      className="w-full rounded-[5px] edit-input text-right"
                      onChange={(e: any) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              </>
            }
            <hr className='hr mt-1 mb-[10px]' />
            <div className='flex mt-[20px] justify-center items-center gap-[10px]'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleExpenses()}>
                {t('Create')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => setShowModalAdd(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </List>
    </Box>
  )
}