export const en = {
  // Nav 
  "Timesheets": "Timesheets",
  "Reports": "Reports",
  "Approve": "Approve",
  "Clients": "Clients",
  "Equipment": "Equipment",
  "Booking": "Booking",
  "Expenses": "Expenses",
  "Orders": "Orders",
  "Admin": "Admin",
  "Users": "Users",
  "Toil": "Toil",
  "UsersReports": "Users Reports",
  // Header menu 
  "Setting": "Settings",
  "Profile": "Profile",
  "LogOut": "Log Out",
  "Login": "Login",
  "Search": "Search...",
  "Notification": "Notifications",
  // Report table 
  "Date": "Date",
  "StartTime": "Start Time",
  "EndTime": "End Time",
  "Category": "Category",
  "Task": "Task",
  "Description": "Description",
  "Supervisor": "Supervisor",
  "Hours": "Hours",
  "Edit": "Edit",
  "Download": "Download",
  "AreYouSureDownload": "Are you sure you want to download this report?",
  "ReportList": "Report List",
  // Aprove Table 
  "TotalUserHours": "Total User Hours",
  "TotalCategoryHours": "Total Category Hours",
  "Name": "Name",
  "Total": "Total",
  "ApproveAll": "Approve All",
  "Return": "Return",
  "ApproveAllForWeek": "Approve Week",
  "TotalWeek": "Total Week Hours",
  "RemoveAllForWeek": "Return Week",
  "AreYouSureBack": "Are you sure you want return all week?",
  "RejectMessage": "Please provide a reason for returning the report",
  "ApproveList": "Approve List",
  // Timesheets 
  "AreYouSureReport": "Are you sure you want to submit a weekly report?",
  "Add": "Add",
  "SendTo": "Send to Approve",
  "SelectUsers": "Select Users",
  "ReturnWeek": "Return Week",
  "SelectTemplate": "Select Template",
  "PerWee": "Per Week",
  "TOIL": "TOIL",
  "TimesheetsList": "Timesheets List",
  "AddReport": "Add Report",
  "ThisWeek": "This Week",
  "SelectCategory": "Select category...",
  "DescriptionPoins": "Description...",
  "AreYouSureBackWeek":"Are you sure you want to return this week?",
  // Expenses
  "AreYouSureChangeStatus":"Are you sure you want change",
  "StatusExp":"status(es)",
  "to":"to",
  "StatusExpense": "Status",
  "AddExpenses":"Add Expenses",
  "NameCreator":"Name Creator",
  "Staff":"Staff",
  "Amount":"Amount",
  "Phone":"Phone",
  "AddUserInfo":"Add User Info",
  "Project":"Project",
  "ExensesList":"Exenses List",
  "Period":"Period",
  "Time":"Time",
  // Equipment
  "Import": "Import",
  "AreYouSureEquipment": "Are you sure you want download this sample?",
  "AreYouSureEquipmentAll": "Are you sure you want download select equipments?",
  "AddEqp": "Add Equipment",
  "EqpList": "Equipment List",
  "Number": "Number",
  "Item": "Item",
  "Manufacturer": "Manufacturer",
  "Model": "Model",
  "SerialNumber": "Serial Number",
  "Responsive": "Responsive",
  "PurchaseDate": "Purchase Date",
  "Cost": "Cost",
  "PATTestDate": "PAT Test Date",
  "PATTestDue": "PAT Test Due",
  "PATTestUser": "PAT Test User",
  "Note": "Note",
  "ResponsibleForItem": "Responsible for Item:",
  "WarrantyDue": "Warranty Due",
  "ItemInfo": "Item info",
  "PATInfo": "PAT info",
  "ResponsibleForPAT": "Responsible for PAT",
  "EditEquipment": "Edit Equipment",
  "Eqp": "Equipment",
  "RentalOpportunity": "Rental Opportunity",
  "InventoryID": "Inventory ID",
  "EditEqp": "Edit Equipment",
  "InventoryDate": "Inventory Date",
  "InventoryUser": "Inventory User",
  "InventoryInfo": "Inventory Info",
  "SelectTheNumberOfMonths": "Select the number of months",
  "Selected": "selected",
  //Template
  "Template": "Template",
  "Temp": "Template",
  "CreateNew": "Create New",
  "MyTemplates": "My Templates",
  "EditTemp": "Edit Template",
  "To": "to",
  "Empty": "Empty",
  "AddTempate": "Add Tempate",
  "Tempate": "Tempate",
  "мaximum20characters": "мaximum 20 characters",
  "AddTo": "Add to",
  "InsertTemp":"Insert this template into the report?",
  //Booking
  "BookingCalendar": "Booking Calendar",
  "NoBookingsAvailable": "No bookings available",
  // Reports 
  "SortBy": "Sort by",
  "User": "User",
  "StatusReport": "Status",
  "Export": "Export",
  "AddFilters": "Add Filters",
  // Users 
  "Email": "Email",
  "Role": "Role",
  "users": "users",
  "UsersList": "Users List",
  "AddUser": "Add User",
  "fillter": "filter...",
  "EditUser": "Edit User",
  "PersonalInformation": "Personal Information",
  "LastName": "Last Name",
  "SecondName": "Second Name",
  "DateOfBirth": "Date of Birth",
  "Sex": "Sex",
  "WorkInformation": "Work Information",
  "JobTitle": "Job Title",
  "ContractStartDate": "Contract Start Date",
  "Department": "Department",
  "ContractType": "Contract Type",
  "ContractedHoursPerWeek": "Contracted hours per week",
  "MinLeaveEntitlement": "Min. leave entitlement",
  "ContactInformation": "Contact Information",
  "MobilePhone": "Mobile Phone",
  "WorkPhone": "Work Phone",
  "Postcode": "Postcode",
  "City": "City",
  "Address": "Address",
  "OtherSettings": "Other Settings",
  "TimeZone": "Time Zone",
  "NotificationLanguage": "Notification Language",
  "ChangePassword": "Change Password",
  "RegisterUser": "Register User",
  "RegistrationInformation": "Registration Information",
  "Password": "Password",
  "LastLogin": "Last Login",
  "View": "View",
  "ConfirmPassword": "Confirm Password",
  "FirstName": "First Name",
  "AreYouUser": "Are you sure you want delete this user?",
  // Settings
  "CompanyInfo": "Company Info",
  "CompanyName": "Company Name",
  "EditPanel": "Edit Panel",
  "Settings": 'Settings',
  "AddCategory": "Add Category",
  "AddChildCategoryFor": "Add child category for",
  "SelectSupervisor": "Select supervisor",
  "AddParentCategory": "Add parent category",
  "ChangeNameCategory": "Change name category",
  "ChangeParents": "Change Parents",
  "AreYouSure": "Are you sure you want to delete this",
  "Sourc": "Source",
  "LegalEntityType": "Legal Entity Type",
  "LegalEntity": "Legal Entity",
  "LegalEntityFix": "Legal Entity",
  "Sour": "source",
  "Cat": "Category",
  "Cont": "Contract",
  "Dep": "Department",
  "Loc": "Location",
  "Stat": "Status",
  "Equ": "Category Equipment",
  "CatEqu": "Category Equipment",
  "CatEqupm": "Category Equipment",
  "Tasks": "Task",
  "ChangeName": "Change name",
  "AddRole": "Add Role",
  "EnterNameOf": "Enter Name of",
  "EditRole": "Edit Role",
  "RoleName": "Role Name",
  "ColorRole": "Color Role",
  "RoleAccess": "Role Access",
  "Delete": "Delete",
  "ShowAll": "Show All",
  "AddEdit": "Add/Edit",
  "AreYouSureRole": "Are you sure you want to delete this Role?",
  "Create": "Create",
  "Location": "Location",
  "Status": "Status",
  "Contract": "Contract",
  "TasksType": "Task",
  "CreateRole": "Create Role",
  "Roles": "Roles",
  "Availability": "Availability",
  "CreateEdit": "Create/Edit",
  "EnterNameOfRole": "Enter Name of Role",
  // Finance
  "SalaryPerHours": "Salary Per Hours",
  "AddNewSalary": "Add New Salary",
  "DateNewSalary": "Date New Salary",
  "StartDate": "Start Date",
  "CostPerHour": "Cost Per Hour",
  "PerWeek": "Per Week",
  "History": "History",
  "EndDate": "End Date",
  "RemoveAll": "Remove All",
  "Information": "Information",
  "empty": "empty",
  // TOIL 
  "TotalTOIL": "Total TOIL",
  "AddToils": "Add TOIL",
  "AddInformation": "Add information",
  "CreatingDate": "Creating date",
  "TotalHours": "Total hours",
  "OursPerWeek": "Ours per week",
  "Source": "Source",
  "removeTOIL": "TOIL Correction",
  "discriptions": "Descriptions",
  "Refresh": "Refresh",
  // Tasks 
  "AllTasks": "All tasks",
  "SelectTasksPoins": "Select Tasks...",
  // Edit Reports 
  "EditReports": "Edit Reports",
  "EditDate": "Edit Date",
  "EditCategory": "Edit Category",
  "SelectTasks": "Select Tasks",
  "EditDescription": "Edit Description",
  // Delete modal 
  "AreYouReport": "Are you sure you want to delete these Reports?",
  "AreYouReportCat": "Are you sure you want to delete this Category?",
  "AreYouReportCatModal": "Are you sure you want to delete this category",
  // All button 
  "Yes": "Yes",
  "No": "No",
  "Cancel": "Cancel",
  "Change": "Change",
  "Register": "Register",
  "Close": "Close",
  // Other 
  "underDevelopment": "Apologies, this section is currently under development",
  "січень": "January",
  "лютий": "February",
  "березень": "March",
  "квітень": "April",
  "травень": "May",
  "червень": "June",
  "липень": "July",
  "серпень": "August",
  "вересень": "September",
  "жовтень": "October",
  "листопад": "November",
  "грудень": "December",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
}