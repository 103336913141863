import { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import EquipmentTable from './equipmentComponents/EquipmentTable';
import AddEquipment from './modal/AddEquipment';
import Drawer from '@mui/material/Drawer';
import { t } from 'i18next';
import EditEquipment from './modal/EditEquipment';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import AproveDownload from './modal/AproveDownload';
import DownloadIcon from '@mui/icons-material/Download';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PublishIcon from '@mui/icons-material/Publish';
import ShowNewLocation from './modal/ShowNewLocation';
import ShowToDo from './modal/ShowToDo';
import ShowNon from './modal/ShowNon';
import FileSaver from 'file-saver';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface EquipmentProps {
  access: any
}

const Equipment: React.FC<EquipmentProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'none'>('none');
  const [status, setStatus] = useState<any[]>([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null>(null);
  const [show, setShow] = useState(false);
  const [locationRow, setLocationRow] = useState();
  const [categoryRow, setCategoryRow] = useState();
  const [dublicateRow, setDublicateRow] = useState();
  const [dublicateWhisRow, setDublicateWhisRow] = useState();
  const [errorNameRow, setErrorNameRow] = useState();
  const [statusRow, setStatusRow] = useState();
  const [errorRow, setErrorRow] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [openShow, setOpenShow] = useState(false);
  const [openDo, setOpenDo] = useState(false);
  const [openNon, setOpenNon] = useState(false);
  const [equip, setEquip] = useState([]);
  const [equipOriginal, setEquipOrifinal] = useState([]);
  const [userID, setUserID] = useState();
  const location = useLocation();
  const [file, setFile] = useState<File | null>(null);
  const [showAprove, setShowAprove] = useState(false);
  const [showAproveEquipment, setShowAproveEquipment] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<number | null>(null);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleShowAdd = () => {
    setShowModalAdd(true);
  }

  //open EquipEdit
  const handleShow = (equip: any) => {
    setSelectedUser(equip);
    setShow(true);
  };

  //refresh equipment
  const refreshEquip = async () => {
    try {
      const { data: usersEquip } = await axios.get('equipment');
      if (usersEquip) {
        const sortedEquip = usersEquip.sort((a: any, b: any) => a.inventory_id - b.inventory_id);
        setEquip(sortedEquip);
      }
    } catch (error: any) {
      console.log('error users');
    }
  }

  //get all data and check user
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        const { data: usersEquip } = await axios.get('equipment');
        const { data: statusData } = await axios.get('status');
        if (data) {
          setUserID(data.id);
          dispatch(setAuth(true));
        }
        if (usersEquip) {
          const sortedEquip = usersEquip.sort((a: any, b: any) => a.inventory_id - b.inventory_id);
          setEquip(sortedEquip);
          setEquipOrifinal(usersEquip);
        }
        if (statusData) {
          const activeStatus = statusData.filter((stat: any) => stat.active);
          setStatus(activeStatus);
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname]);

  // export select equipment 
  const handleEquipmentXls = async () => {
    try {
      const payload = { equipmentId: selected };
      const response = await axios.post('equipment/export/xlsx', payload, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'equipment.xlsx');
      enqueueSnackbar('File successfully saved', { variant: 'success' });
      setShowAproveEquipment(false);
    } catch (error) {
      enqueueSnackbar(`Error updating reports`, { variant: 'error' });
    }
  };

  //download file (import)
  const importCSV = async (id: any, item: any) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('equipment', file);
        await axios.post(`equipment/upload/?saveConfig_id=${id}&saveConfig_error=${item}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        enqueueSnackbar(`${file.name} uploading success`, { variant: 'success' });
        refreshEquip();
        setFile(null);
      } catch (error: any) {
        if (error.response?.status === 404) {
          setLocationRow(error.response?.data.newLocation);
          setCategoryRow(error.response?.data.newCategoryEquipment);
          setStatusRow(error.response?.data.newStatus);
          setOpenShow(true);
        } else if (error.response?.status === 403) {
          setDublicateRow(error.response?.data.idDublicate);
          setErrorMessage(error.response?.data.message);
          setErrorNameRow(error.response?.data.errorName);
          setDublicateWhisRow(error.response?.data.dublicate);
          setOpenNon(true);
        } else if (error.response?.status === 401) {
          enqueueSnackbar('You do not have permission', { variant: 'error' });
        } else {
          enqueueSnackbar('Error uploading file', { variant: 'error' });
          setErrorRow(error.response?.data.error);
          setOpenDo(true);
        }
      }
    }
  }

  const downloadSampleXLS = async () => {
    try {
      const response = await axios.post('equipment/csv', null, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample_equipment.xlsx');
      document.body.appendChild(link);
      link.click();
      setShowAprove(false);
    } catch (error) {
      console.error('Error downloading sample xlsx:', error);
      enqueueSnackbar('Error downloading sample xlsx', { variant: 'error' });
    }
  };

  return (
    <>
      {auth && access.equipment_get > 0 ?
        (<div>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div className='flex justify-between items-center gap-4 w-[55%]'>
                <div className="flex gap-3 justify-start items-center w-full">
                  <Button
                    variant="contained"
                    endIcon={<AddToQueueIcon
                      sx={{ color: 'white !important' }} />}
                    sx={{
                      padding: '5px 10px',
                      fontSize: 14,
                      color: 'white !important',
                      whiteSpace: 'nowrap'
                    }}
                    onClick={handleShowAdd}>
                    {t('AddEqp')}
                  </Button>
                  <input
                    type="text"
                    className='w-[60%] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                    placeholder={t('fillter')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const searchText = e.target.value.toLowerCase();
                      const filteredUsers = equipOriginal.filter((eq: any) =>
                        (eq.item_name && eq.item_name.toLowerCase().includes(searchText)) ||
                        (eq.inventory_id && String(eq.inventory_id).includes(searchText)) ||
                        (eq.manufacturer && eq.manufacturer.toLowerCase().includes(searchText)) ||
                        (eq.model && eq.model.toLowerCase().includes(searchText))
                      );
                      setEquip(filteredUsers);
                    }}
                  />
                </div>
              </div>
              <div
                className='flex gap-[10px]'>
                <div className='flex gap-2 items-center'>
                  <p className='text-[14px] font-semibold media-one'>{t('SortBy')}:</p>
                  <select
                    //transfer the value to sortDirection
                    className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                    value={sortDirection}
                    onChange={(e) => setSortDirection(e.target.value as 'asc' | 'desc' | 'none')}>
                    <option value="none">none</option>
                    <option value="asc">last name A-Z</option>
                    <option value="desc">first name Z-A</option>
                  </select>
                  <select
                    // select status
                    className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                    value={selectedStatusFilter !== null ? selectedStatusFilter : 'all'}
                    onChange={(e) => setSelectedStatusFilter(e.target.value === 'all' ? null : Number(e.target.value))}>
                    <option value="all">all status</option>
                    {status.map((status) => (
                      <option key={status.id} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px] relative pt-[70px]'>
            <div className='flex justify-between items-center mb-5 absolute bg-[var(--bg-top)] top-0 w-full left-0 px-[20px] py-[10px] rounded-t-[5px]'>
              <h3 className='text-[18px]'>{t('EqpList')}:</h3>
              <div className='flex gap-[10px] items-center'>
                <Button
                  color="secondary"
                  startIcon={<DownloadIcon />}
                  variant="outlined"
                  onClick={() => setShowAprove(true)}
                  sx={{
                    padding: '3px 10px'
                  }}>
                  {t('Sample')} XLSX
                </Button>
                <div className='flex gap-[10px] items-center'>
                  {file && (
                    <>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className='cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                        onClick={() => setFile(null)} />
                      <div className='flex justify-center mt-[10px] text-[14px]'>
                        <span> {file.name}</span>
                      </div>
                    </>
                  )}
                  <Button
                    component={!file ? "label" : "button"}
                    color={file ? "success" : undefined}
                    startIcon={!file ? <ExitToAppIcon /> : <PublishIcon />}
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                      padding: '3px 10px'
                    }}
                    onClick={() => file && importCSV(1, 1)}
                  >
                    {file ? t('Import') : t('Browse')} File
                    {!file && (
                      <VisuallyHiddenInput
                        type="file"
                        accept=".xlsx"
                        onChange={(e: any) => {
                          setFile(e.target.files ? e.target.files[0] : null);
                        }}
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <EquipmentTable
              access={access}
              equip={equip}
              handleShow={handleShow}
              sortDirection={sortDirection}
              status={status}
              userID={userID}
              refreshEquip={refreshEquip}
              selectedStatusFilter={selectedStatusFilter}
              selected={selected}
              setSelected={setSelected}
              setShowAproveEquipment={setShowAproveEquipment} />
          </div>
        </div>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
      {openShow && <ShowNewLocation
        openShow={openShow}
        setOpenShow={setOpenShow}
        locationRow={locationRow}
        categoryRow={categoryRow}
        statusRow={statusRow}
        importCSV={importCSV}
      />}
      {openDo && <ShowToDo
        openShow={openDo}
        setOpenShow={setOpenDo}
        errorRow={errorRow}
        importCSV={importCSV}
      />}
      {openNon && <ShowNon
        openShow={openNon}
        setOpenShow={setOpenNon}
        errorMessage={errorMessage}
        errorNameRow={errorNameRow}
        dublicateRow={dublicateRow}
        dublicateWhisRow={dublicateWhisRow}
        importCSV={importCSV}
      />}
      <Drawer
        anchor="right"
        open={showModalAdd}
        onClose={() => setShowModalAdd(false)}>
        <AddEquipment
          setShowModalAdd={setShowModalAdd}
          refreshEquip={refreshEquip}
          status={status} />
      </Drawer>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        <EditEquipment
          setShow={setShow}
          status={status}
          refreshEquip={refreshEquip}
          selectedUser={selectedUser} />
      </Drawer>
      {showAprove &&
        <AproveDownload
          showAprove={showAprove}
          setShowAprove={setShowAprove}
          handXls={downloadSampleXLS}
          textField={'AreYouSureEquipment'} />}
      {showAproveEquipment &&
        <AproveDownload
          showAprove={showAproveEquipment}
          setShowAprove={setShowAproveEquipment}
          handXls={handleEquipmentXls}
          textField={'AreYouSureEquipmentAll'} />}
    </>
  )
}

export default Equipment
