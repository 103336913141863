import { useEffect } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';

interface OrdersProps {
  access: any
}

const Orders: React.FC<OrdersProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname]);
  return (
    <>
      {auth && access.orders_get > 0 ?
        (<div>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-center opacity-50'>{t('underDevelopment')}</div>
          </div>
        </div>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
    </>
  )
}

export default Orders
