import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

interface SelectProps {
  selectedTask: any,
  setSelectedTask: any,
  category: any,
  reportID: any,
  selectedCategoryId: any,
  taskOrTasks: any
}


const TaskEdit: React.FC<SelectProps> = ({
  selectedTask,
  taskOrTasks,
  setSelectedTask,
  category,
  reportID,
  selectedCategoryId }) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const [selectTask, setSelectTask] = useState<any[]>([]);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTask(taskOrTasks ? selectedOptions : selectedOptions ? [selectedOptions] : []);
  };

  useEffect(() => {
    (async () => {
      try {
        if (selectedCategoryId) {
          const { data: categoryCheck } = await axios.get(`category/${selectedCategoryId}`);
          const formattedTasks = categoryCheck.task.map((task: any) => ({
            value: task.id,
            label: task.name,
          }));
          setTasks(formattedTasks);
        } else {
          setTasks([]);
        }
      } catch (e) {
      }
    })();
  }, [selectedCategoryId]);

  useEffect(() => {
    if (reportID.categoryId === Number(selectedCategoryId)) {
      const formattedTasks = reportID.tasks.map((task: any) => ({
        value: task.id,
        label: task.name,
      }));
      setSelectTask(formattedTasks);
      setSelectedTask(formattedTasks);
    } else {
      setSelectTask([]);
      setSelectedTask([]);
    }
  }, [reportID, selectedCategoryId]);

  useEffect(() => {
    setSelectTask(selectedTask);
  }, [selectedTask]);

  return (
    <Select
      isMulti={taskOrTasks}
      closeMenuOnSelect={!taskOrTasks}
      name="colors"
      placeholder='Select Tasks...'
      value={selectTask}
      onChange={handleSelectChange}
      options={tasks}
      className="basic-multi-select w-full task-edit-fix bg-[var(--bg-input)] text-left"
      classNamePrefix="select"
    />
  )
}

export default TaskEdit
