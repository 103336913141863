import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DeleteTemplate from '../modal/DeleteTemplate';
import Drawer from '@mui/material/Drawer';
import EditTemp from '../modal/EditTemp';
import EditTemplate from '../modal/EditTemplate';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)',
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '5px',
  backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 12,
}));

interface Category {
  id: number;
  name: string;
  parentId?: number | null;
}

interface Row {
  id: number;
  temp_group_number: number;
  temp_name: string;
  day_number: number;
  endDay_number: number;
  startTime: string;
  endTime: string;
  description: string;
  userId: number;
  taskId: string;
  categoryId: number;
  companyId: number;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

interface ReportsTableProps {
  rows: Row[];
  refresh: any;
  allCategory: Category[];
  tasks: any;
  is24HourFormat: any;
  usersSuperId: any;
  refreshSelectTemplate: any;
  taskOrTasks: any;
  children?: React.ReactNode;
}

const TemplateView: React.FC<ReportsTableProps> = ({
  rows,
  refresh,
  allCategory,
  tasks,
  is24HourFormat,
  usersSuperId,
  refreshSelectTemplate,
  taskOrTasks }) => {
  const [tableVisibility, setTableVisibility] = useState<{ [key: string]: boolean }>({});
  const [selectedDateId, setSelectedDateId] = useState<number | null>(null);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [teamIdTemp, setTeamIdTemp] = useState<number | null>(null);
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditTemp, setOpenEditTemp] = useState(false);

  const handleDeleteClick = useCallback((id: number, group: string) => {
    setSelectedDateId(id);
    setGroup(group);
    setOpen(true);
  }, []);

  const handleEditClick = useCallback((id: number) => {
    if (id) {
      setTeamId(id);
    }
    setOpenEdit(true);
  }, []);

  const handleEditTemplate = useCallback((id: number) => {
    setTeamIdTemp(id);
    setOpenEditTemp(true);
  }, []);

  useEffect(() => {
  }, [selectedDateId, setCheckOpenModal]);

  const groupedReports = useMemo(() => {
    return rows.reduce((acc: { [key: number]: { [key: string]: Row[] } }, row) => {
      acc[row.temp_group_number] = acc[row.temp_group_number] || {};
      acc[row.temp_group_number][row.temp_name] = acc[row.temp_group_number][row.temp_name] || [];
      acc[row.temp_group_number][row.temp_name].push(row);
      return acc;
    }, {});
  }, [rows]);

  const toggleTableVisibility = useCallback(
    (groupNumber: string, tempName: string) => {
      const key = `${groupNumber}_${tempName}`;
      setTableVisibility((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    []
  );

  const getCategoryPath = (categoryId: number): string => {
    const category = allCategory.find((cat: Category) => cat.id === categoryId);
    if (!category) return '';
    let path = category.name;
    let parentId = category.parentId;
    while (parentId) {
      const parentCategory = allCategory.find((cat: Category) => cat.id === parentId);
      if (parentCategory) {
        path = `${parentCategory.name} > ${path}`;
        parentId = parentCategory.parentId;
      } else {
        break;
      }
    }
    return path;
  };

  const getTaskNamesByIds = (taskIds: string): string => {
    const idsArray = taskIds.split(',').map(Number);
    const taskNames = idsArray.map(id => {
      const task = tasks.find((task: any) => task.id === id);
      return task ? task.name : '';
    });
    return taskNames.filter(name => name).join(', ');
  };

  const getDayOfWeek = (dayNumber: number): string => {
    const daysOfWeek = [
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday'),
      t('Sunday'),
    ];
    return daysOfWeek[dayNumber - 1] || t('Invalid Day');
  };

  const renderUserReports = useMemo(() => {
    return Object.entries(groupedReports).map(([groupNumber, tempGroups]) => (
      <div key={groupNumber}>
        {Object.entries(tempGroups).map(([temp_name, groupRows]) => (
          <div
            key={temp_name}
            className={`text-center rounded-[5px] mb-[10px] px-[10px] bg-[var(--bg-aprove)] user-box-view-all ${tableVisibility[temp_name] ? 'pb-[10px]' : 'hover:bg-[var(--bg-header-fix-block)]'}`}>
            <div className='flex justify-between items-center p-[10px] cursor-pointer'>
              <div
                onClick={() => toggleTableVisibility(groupNumber, temp_name)}
                className='flex gap-[5px] items-center'>
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className={`cursor-pointer opacity-70 px-[8px] py-[5px] rounded-full transition-[1s] hover:bg-gray-500 hover:text-white ${tableVisibility[temp_name] ? 'rotate-0' : 'rotate-180'}`}
                />
                <h3>{temp_name}</h3>
              </div>
              <div className='flex items-center'>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => handleEditClick(groupRows[0].temp_group_number)}
                  className='text-[14px] cursor-pointer opacity-50 rounded-full px-[5px] py-[5px] text-[var(--text-color)] hover:opacity-70 hover:bg-gray-500 hover:text-white'
                />
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[14px] cursor-pointer opacity-50 text-[var(--text-color)] rounded-full px-[5px] py-[5px] hover:opacity-70 hover:bg-gray-500 hover:text-white'
                  onClick={() => handleDeleteClick(groupRows[0].temp_group_number, 'group/')}
                />
              </div>
            </div>
            {tableVisibility[`${groupNumber}_${temp_name}`] && (
              <TableContainer component={Paper} style={{ boxShadow: 'none', backgroundColor: 'inherit' }}>
                <Table sx={{ minWidth: 350 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t('Date')}</StyledTableCell>
                      <StyledTableCell>{t('StartTime')}</StyledTableCell>
                      <StyledTableCell>{t('EndTime')}</StyledTableCell>
                      <StyledTableCell>{t('Category')}</StyledTableCell>
                      <StyledTableCell>{t('Task')}</StyledTableCell>
                      <StyledTableCell>{t('Description')}</StyledTableCell>
                      <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupRows
                      .sort((a, b) => {
                        const dayCompare = Number(a.day_number) - Number(b.day_number);
                        if (dayCompare !== 0) return dayCompare;
                        return moment(a.startTime, 'HH:mm').isBefore(moment(b.startTime, 'HH:mm')) ? -1 : 1;
                      })
                      .map((row) => (
                        <TableRow key={row.id}>
                          <StyledTableRow>{getDayOfWeek(Number(row.day_number))}</StyledTableRow>
                          <StyledTableRow>{row.startTime}</StyledTableRow>
                          <StyledTableRow>{row.endTime}</StyledTableRow>
                          <StyledTableRow>{getCategoryPath(row.categoryId)}</StyledTableRow>
                          <StyledTableRow>{getTaskNamesByIds(row.taskId)}</StyledTableRow>
                          <StyledTableRow>{row.description}</StyledTableRow>
                          <StyledTableRow align="right">
                            <div
                              className='flex gap-[7px] justify-end items-center'>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() => handleEditTemplate(row.id)}
                                className='text-[14px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                              />
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className='text-[12px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleDeleteClick(row.id, '')} />
                            </div>
                          </StyledTableRow>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        ))}
      </div>
    ));
  }, [groupedReports, tableVisibility, toggleTableVisibility, handleDeleteClick]);

  return rows.length === 0 ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <CircularProgress />
    </Box>
  ) : (
    <div>
      {renderUserReports}
      {open &&
        <DeleteTemplate
          open={open}
          setOpen={setOpen}
          selectedDateId={selectedDateId}
          refresh={refresh}
          group={group}>
        </DeleteTemplate>}
      <Drawer
        anchor="right"
        open={openEdit}
        onClose={() => setOpenEdit(false)}>
        <EditTemp
          setOpenEdit={setOpenEdit}
          teamId={teamId}
          refresh={refresh}
          usersSuperId={usersSuperId}
          refreshSelectTemplate={refreshSelectTemplate} />
      </Drawer>
      <Drawer
        anchor="right"
        open={openEditTemp}
        onClose={() => setOpenEditTemp(false)}>
        <EditTemplate
          setOpenEditTemp={setOpenEditTemp}
          teamIdTemp={teamIdTemp}
          taskOrTasks={taskOrTasks}
          is24HourFormat={is24HourFormat}
          openCheckModal={openCheckModal}
          refresh={refresh} />
      </Drawer>
    </div>
  );
};

export default TemplateView;
