import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan, faCheckDouble, faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DeleteModal from '../modal/DeleteModal';
import Drawer from '@mui/material/Drawer';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import EditReports from '../modal/EditReports';
import 'moment-timezone';
import { t } from 'i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import DeleteArrayAll from '../modal/DeleteArrayAll';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  // backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableCheck = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '0 0 0 8px',
  // backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface Row {
  id: any,
  dateId: number;
  date: string;
  startTime: string;
  endTime: string;
  categoryid: any;
  description: string;
  allTime: string;
  status: any;
  tasks: any;
  supervisorFirstName: string;
  supervisorLastName: string;
  categoryTree: string;
}

interface ReportsTableProps {
  rows: Row[];
  updateReports: any;
  openCheckModal: boolean;
  setCheckOpenModal: any;
  rowsPerPage: any;
  page: any;
  selected: any;
  setSelected: any;
  taskOrTasks: any;
  loading: any;
}

const ReportsTable: React.FC<ReportsTableProps> = ({
  rows,
  updateReports,
  openCheckModal,
  setCheckOpenModal,
  rowsPerPage,
  page,
  loading,
  selected,
  setSelected,
  taskOrTasks }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState<number | null>(null);
  const [selectedDescription, setSelectedDescription] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<any | null>(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState<any | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<any | null>(null);
  const [openDel, setOpenDel] = useState(false);
  const handleOpen = (dateId: number) => {
    setSelectedDateId(dateId);
    setOpen(true);
  };

  const handleOpenEdit = (dateId: number, description: any, date: any, startTime: any, endTime: any) => {
    setSelectedDateId(dateId);
    setSelectedDescription(description);
    setSelectedDate(date);
    setSelectedDateFrom(startTime);
    setSelectedDateTo(endTime)
    setOpenEdit(true);
  };

  const handleShowDelete = () => {
    setOpenDel(true);
  };

  const handleDeleteReport = async (dateId: number) => {
    try {
      const response = await axios.post(`time-reports/deleteReports/${dateId}`);
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (error: any) {
      console.error('Error deleting report:', error.message);
      enqueueSnackbar('Error updating report', { variant: 'error' });
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows
        .filter((n: any) => n.status === 'new' || n.status === 'rejected')
        .map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
  }, [rows])

  return (
    !loading ? (
      rows.length ? (
        <div className='mb-[10px] text-center' >
          <div
            className={`flex items-center ${selected.length > 0 ? 'py-[5px] px-[10px]' : ''}`}
            style={{
              backgroundColor: selected.length > 0 ? 'rgba(25, 118, 210, 0.12)' : 'transparent',
            }}>
            {selected.length > 0 ? (
              <div
                className='flex justify-between w-full'>
                {selected.length} {t('Selected')}
              </div>
            ) : (<></>)}
            {selected.length > 0 && (
              <div className='flex gap-[20px] items-center'>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                  onClick={() => handleShowDelete()}
                />
              </div>
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 1025 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < rows.filter((n: any) => n.status === 'new' || n.status === 'rejected').length
                      }
                      checked={
                        rows.filter((n: any) => n.status === 'new' || n.status === 'rejected').length > 0 &&
                        selected.length === rows.filter((n: any) => n.status === 'new' || n.status === 'rejected').length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all equipment',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{t('Date')}</StyledTableCell>
                  <StyledTableCell>{t('StartTime')}</StyledTableCell>
                  <StyledTableCell>{t('EndTime')}</StyledTableCell>
                  <StyledTableCell>{t('Category')}</StyledTableCell>
                  <StyledTableCell>{t('Task')}</StyledTableCell>
                  <StyledTableCell>{t('Description')}</StyledTableCell>
                  <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                  <StyledTableCell align="right">{t('Hours')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={row.dateId || index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={`row-${row.status}`}>
                    <StyledTableCheck padding="checkbox">
                      {row.status === 'new' || row.status === 'rejected' ? (
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(row.id) !== -1}
                          onChange={(event: any) => handleClick(event, row.id)}
                        />
                      ) : null}
                    </StyledTableCheck>
                    <StyledTableRow
                      component="th"
                      scope="row">
                      {moment(row.date).format('DD.MM.YYYY')}
                    </StyledTableRow>
                    <StyledTableRow>{moment.utc(row.startTime).format('HH:mm')}</StyledTableRow>
                    <StyledTableRow>{moment.utc(row.endTime).format('HH:mm')}</StyledTableRow>
                    <StyledTableRow>{row.categoryTree}</StyledTableRow>
                    <StyledTableRow>{row.tasks ? (
                      row.tasks.map((task: any) => task.name).join(', ')
                    ) : null}</StyledTableRow>
                    <StyledTableRow>{row.description}</StyledTableRow>
                    <StyledTableRow>{row.supervisorFirstName} {row.supervisorLastName}</StyledTableRow>
                    <StyledTableRow align="right">{row.allTime}</StyledTableRow>
                    <StyledTableRow align="right">
                      {row.status === 'pending' ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='text-[16px] cursor-pointer opacity-40 text-[var(--text-color)]' />
                      ) : row.status === 'fulfilled' ? (
                        <FontAwesomeIcon
                          icon={faCheckDouble}
                          className='text-[16px] cursor-pointer opacity-40 text-[var(--text-color)]' />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className='text-[16px] mr-[8px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                          onClick={() => handleOpenEdit(row.dateId, row.description, row.date, row.startTime, row.endTime)} />
                      )}
                      {row.status === 'pending' || row.status === 'fulfilled' ? (
                        null
                      ) :
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                          onClick={() => handleOpen(row.dateId)} />}
                    </StyledTableRow>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteModal
            open={open}
            setOpen={setOpen}
            handleDeleteReport={handleDeleteReport}
            selectedDateId={selectedDateId}
            updateReports={updateReports} />
          <Drawer
            anchor="right"
            open={openEdit}
            onClose={() => setOpenEdit(false)}>
            <EditReports
              openCheckModal={openCheckModal}
              setCheckOpenModal={setCheckOpenModal}
              // openEdit={openEdit}
              setOpenEdit={setOpenEdit}
              selectedDateId={selectedDateId}
              selectedDescription={selectedDescription}
              selectedDate={selectedDate}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              updateReports={updateReports}
              taskOrTasks={taskOrTasks} />
          </Drawer>
          {openDel && <DeleteArrayAll
            open={openDel}
            setOpen={setOpenDel}
            refresh={updateReports}
            selectedArrayId={selected}
            setSelected={setSelected}
            wordMenu={'reports'}
            pd={'report'}
          />}
        </div >) : (
        <div className='flex items-center justify-center'>
          <div className='opacity-45'>{t('Empty')}</div>
        </div>
      )) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>)
  );
};

export default ReportsTable;