import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { t } from 'i18next';
import Button from '@mui/material/Button';
import moment from 'moment';
import UsersSalaryTable from '../financeComponents/UsersSalaryTable';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteModalAll from './DeleteModalAll';

registerLocale('en-GB', enGB);

interface TemporaryDrawerProps {
  selectedUser: any;
  refreshUsers: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserFenance({
  selectedUser,
  refreshUsers,
  setShow }: TemporaryDrawerProps) {
  const [userName, setUserName] = useState('');
  const [financeRow, setFinanceRow] = useState([]);
  const [userID, setUserID] = useState('');
  const [userLast, setUserLast] = useState('');
  const [userSecond, setUserSecond] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [salaryPerHour, setSalaryPerHour] = useState('');
  const [salaryPerHourNew, setSalaryPerHourNew] = useState('');
  const [perWeek, setPerWeek] = useState('');
  const [contractDate, setContractDate] = useState<any | null>(null);
  const [salaryDate, setSalaryDate] = useState<any | null>(null);
  const [contract, setContract] = useState<any | null>(null);
  const [contractAll, setContractAll] = useState<any | null>(null);
  const [openDel, setOpenDel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      if (selectedUser) {
        try {
          const { data } = await axios.get(`user/${selectedUser.id}`);
          const { data: Cont } = await axios.get(`contract-type`);
          if (Cont) {
            const activeContract = Cont.filter((contract: any) => contract.active);
            setContractAll(activeContract);
          }
          if (data) {
            setUserSecond(data.second_name);
            setUserName(data.first_name);
            setUserLast(data.last_name);
            setJobTitle(data.job_title);
            setPerWeek(data.hoursPerWeek);
            setSalaryPerHour(data.salary_perHour);
            setContractDate(data.contract_start_date);
            setContract(data.contractTypeId);
            if (selectedUser) {
              setUserID(selectedUser.id)
            }
          }
        } catch (e) {
        }
      }
    })();
  }, [selectedUser]);

  const handleSelectChangeContract = (event: any) => {
    setContract(Number(event.target.value));
  };

  const handleDateContractChange = (date: Date) => {
    setContractDate(date);
  };

  const handSalary = (date: Date) => {
    setSalaryDate(date);
  };

  const handleInputChange = (e: any, setState: any, tire: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(new RegExp(`[^0-9${tire}.,]`, 'g'), '');
    inputValue = inputValue.replace(',', '.');
    const parts = inputValue.split('.');
    if (parts.length > 2) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }
    setState(inputValue);
  };

  const handleChange = async (userId: number) => {
    try {
      await axios.put(`updateUser/${userId}`,
        {
          first_name: userName,
          last_name: userLast,
          second_name: userSecond,
          contract_start_date: contractDate,
          job_title: jobTitle,
          salary_perHour: Number(salaryPerHour),
          hoursPerWeek: Number(perWeek),
          contractTypeId: contract,
        });
      if (salaryPerHourNew !== '' && moment(salaryDate).isValid()) {
        const formattedDate = moment(salaryDate).format('DD.MM.YYYY');
        await axios.post(`finance/add`, {
          salary_perHour: Number(salaryPerHourNew),
          userId: Number(userID),
          startDate: formattedDate
        })
      }
      refreshUsers();
      setShow(false);
      enqueueSnackbar('User updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating user', { variant: 'error' });
      console.error('Error updating user:', error);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (userID) {
          const { data } = await axios.get(`finance/user/${userID}`);
          setFinanceRow(data);
        }
      } catch (e) {
      }
    };
    checkAuth();
  }, [userID]);

  const refreshFinanceHistory = async () => {
    try {
      const { data } = await axios.get(`finance/user/${userID}`);
      setFinanceRow(data.length ? data : []);
      const { data: user } = await axios.get(`user/${selectedUser.id}`);
      if (user) {
        setSalaryPerHour(user.salary_perHour);
      }
    } catch (error: any) {
    }
  };

  const handleHistoryChange = async () => {
    try {
      if (salaryPerHourNew !== '' && moment(salaryDate).isValid()) {
        const formattedDate = moment(salaryDate).format('DD.MM.YYYY');
        await axios.post(`finance/add`, {
          salary_perHour: Number(salaryPerHourNew),
          userId: Number(userID),
          startDate: formattedDate
        })
      } else {
        enqueueSnackbar('Date or Salary did not use', { variant: 'error' });
        return
      }
      refreshUsers();
      refreshFinanceHistory();
      setSalaryDate('');
      setSalaryPerHourNew('');
      enqueueSnackbar('finance updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating user', { variant: 'error' });
      console.error('Error updating user:', error);
    }
  };

  const sortedFinanceRow = [...financeRow].sort((a: any, b: any) => {
    const dateA = a.startDate.split('.').reverse().join('-');
    const dateB = b.startDate.split('.').reverse().join('-');
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  });

  return (
    <Box sx={{
      width: 450,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' id='reg-user-edit'>
          <div className='flex justify-end'>
            <h1 className='text-[20px] text-left font-bold'>{t('EditUser')}</h1>
          </div>
          <hr className='hr mt-1' />
          <div className='flex justify-start my-[10px] pl-[10px] font-semibold'>
            {userName} {userLast} {userSecond}
          </div>
          <div className='flex flex-col justify-evenly flex-grow' >
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('Information')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('ContractType')}</label>
                <select
                  className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={contract || 'none'}
                  onChange={handleSelectChangeContract}>
                  <option value="none">none</option>
                  {contractAll?.map((contract: any) => (
                    <option key={contract.id} value={contract.id}>
                      {contract.title}
                    </option>))}
                </select>
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('ContractStartDate')}</label>
                <DatePicker
                  selected={contractDate ? new Date(contractDate) : null}
                  onChange={(date: any) => handleDateContractChange(date)}
                  className="timesheets-input-all text-right text-[18px] w-full p-[6px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('ContractedHoursPerWeek')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input text-right"
                  value={perWeek ? perWeek : ''}
                  onChange={(e) => handleInputChange(e, setPerWeek, '')} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('SalaryPerHours')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input text-right"
                  value={salaryPerHour ? salaryPerHour : ''}
                  readOnly
                  onChange={(e) => handleInputChange(e, setSalaryPerHour, '')} />
              </div>
            </div>
            <hr className='hr mt-1 mb-[10px]' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('AddNewSalary')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('DateNewSalary')}</label>
                <DatePicker
                  selected={salaryDate ? new Date(salaryDate) : null}
                  onChange={(date: any) => handSalary(date)}
                  className="timesheets-input-all text-right text-[18px] w-full p-[6px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
              <div className='flex gap-[5px] justify-between items-end'>
                <div className='flex flex-col w-full'>
                  <label className='text-[10px] ml-[5px]'>{t('SalaryPerHours')}</label>
                  <input type="text"
                    className="w-full rounded-[5px] edit-input text-right"
                    value={salaryPerHourNew ? salaryPerHourNew : ''}
                    onChange={(e) => handleInputChange(e, setSalaryPerHourNew, '')} />
                </div>
                <Button
                  variant="contained"
                  endIcon={<DataSaverOnIcon
                    sx={{ color: 'white !important' }} />}
                  sx={{
                    padding: '5px 30px',
                    fontSize: 14,
                    color: 'white !important',
                    marginBottom: '5px'
                  }}
                  onClick={() => handleHistoryChange()}>
                  {t('Add')}
                </Button>
              </div>
            </div>
            <hr className='hr mt-1 mb-[10px]' />
            <div className='flex justify-between items-center my-[10px]'>
              {financeRow.length > 0 ?
                (<Button
                  variant="outlined"
                  color="error"
                  endIcon={<HighlightOffIcon
                    sx={{ color: 'var(--text-color)' }} />}
                  sx={{
                    padding: '5px 10px',
                    fontSize: 10,
                    color: 'var(--text-color)',
                    marginBottom: '5px'
                  }}
                  onClick={() => setOpenDel(true)}>
                  {t('RemoveAll')}
                </Button>) : (
                  <div></div>
                )}
              <h2 className='text-[14px] font-semibold text-right'>{t('History')}</h2>
            </div>
            <UsersSalaryTable financeRow={sortedFinanceRow} />
            <hr className='hr mt-1' />
            <div className='flex mt-[20px] justify-center items-center gap-[10px]'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleChange(selectedUser.id)}>
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => setShow(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
          {openDel && <DeleteModalAll
            open={openDel}
            setOpen={setOpenDel}
            refresh={refreshFinanceHistory}
            selectedDateId={selectedUser.id}
            typeName={'history'}
            typeBack={'finance'}
          />}
        </div>
      </List>
    </Box>
  )
}