import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { t } from 'i18next';
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Tooltip from '@mui/material/Tooltip';
import DeleteArrayAll from '../modal/DeleteArrayAll';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Button from '@mui/material/Button';
import StatusArrayAll from '../modal/StatusArrayAll';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px 15px 7px 7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableCheck = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '0 0 0 8px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableSort = styled(TableSortLabel)(({ theme }) => ({
  fontSize: 14,
}));

interface ReportsTableProps {
  access: any,
  expensesRow: any,
  selected: any,
  setSelected: any
  refreshExpenses: any,
  selectedStatusFilter: any
}

const ExpensesTable: React.FC<ReportsTableProps> = ({
  access,
  expensesRow,
  selected,
  setSelected,
  refreshExpenses,
  selectedStatusFilter }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [openDel, setOpenDel] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [wordStatus, setWordStatus] = useState('');
  const [selectedExpensesId, setSelectedExpensesId] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSelectStatus = async (ExpensesId: any) => {
    try {
      const updatedStatus = ExpensesId === 'approved' ? 'fulfilled' : ExpensesId;
      await axios.put(`expenses/${selectedExpensesId}`, { status: updatedStatus });
      refreshExpenses();
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const statusArray = [
    {
      id: 1,
      title: 'pending'
    },
    {
      id: 2,
      title: 'rejected'
    },
    {
      id: 3,
      title: 'approved'
    },
    {
      id: 4,
      title: 'paid'
    },
  ]

  const ChangeStatus = (word: string) => {
    setOpenStatus(true);
    setWordStatus(word);
  }

  const handleOpenMenu = (event: any, userId: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedExpensesId(userId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedExpensesId(null);
  };

  const paginatedUsers = expensesRow
    .filter((user: any) => selectedStatusFilter === null || user.status === selectedStatusFilter)
    .sort((a: any, b: any) => {
      const compareValueA = order === 'asc' ? a.date : b.date;
      const compareValueB = order === 'asc' ? b.date : a.date;
      return compareValueA.localeCompare(compareValueB);
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = expensesRow.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleShowDeleteAll = () => {
    setOpenDel(true);
  };

  const renderStaffInfo = (name: string, contact_number: string, description: string) => (
    <div className='max-w-[150px]'>
      <div>
        NAME: <span className='text-blue-300'>{name}</span>
      </div>
      <div>
        PHONE: <span className='text-blue-300'>{contact_number}</span>
      </div>
      <div>
        DESCRIPTION:
        <div>
          {description}
        </div>
      </div>
    </div>
  );

  const renderDescription = (description: string) => (
    <div className='max-w-[150px]'>
      <div>
        DESCRIPTION:
        <div>
          {description}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
  }, [expensesRow])

  return (
    <>
      {Array.isArray(expensesRow) && expensesRow.length > 0 ? (
        <div className='mb-[10px] text-center'>
          <div
            className={`flex items-center ${selected.length > 0 ? 'py-[5px] px-[10px]' : ''}`}
            style={{
              backgroundColor: selected.length > 0 ? 'rgba(25, 118, 210, 0.12)' : 'transparent',
            }}>
            {selected.length > 0 ? (
              <div
                className='flex justify-between w-full'>
                {selected.length} {t('Selected')}
              </div>
            ) : (<></>)}
            {selected.length > 0 && (
              <div className='flex gap-[20px] items-center'>
                {access.expenses_get === 3 &&
                  <>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{
                        padding: '3px 10px',
                        fontSize: 10,
                      }}
                      onClick={() => ChangeStatus('rejected')}>
                      {t('rejected')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="warning"
                      sx={{
                        padding: '3px 10px',
                        fontSize: 10,
                      }}
                      onClick={() => ChangeStatus('fulfilled')}>
                      {t('approved')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{
                        padding: '3px 10px',
                        fontSize: 10,
                      }}
                      onClick={() => ChangeStatus('paid')}>
                      {t('Paid')}
                    </Button>
                    <hr className="border-l-2 hr h-[25px]" />
                  </>
                }
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[16px] cursor-pointer opacity-50 pr-[10px] text-[var(--text-color)] hover:opacity-70'
                  onClick={() => handleShowDeleteAll()}
                />
              </div>
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < expensesRow.length}
                      checked={expensesRow.length > 0 && selected.length === expensesRow.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all equipment',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSort
                      active={orderBy === 'date'}
                      direction={orderBy === 'date' ? order : 'asc'}
                      onClick={() => handleRequestSort('date')}>
                      {t('Date')}
                    </StyledTableSort>
                  </StyledTableCell>
                  <StyledTableCell>{t('NameCreator')}</StyledTableCell>
                  <StyledTableCell>{t('Department')}</StyledTableCell>
                  <StyledTableCell>{t('Project')}</StyledTableCell>
                  <StyledTableCell>{t('Category')}</StyledTableCell>
                  <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Staff')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('StatusExpense')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Amount')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user: any, index: any) => {
                  return (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={`exe-${user.status}`}>
                      <StyledTableCheck padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(user.id) !== -1}
                          onChange={(event: any) => handleClick(event, user.id)}
                        />
                      </StyledTableCheck>
                      <StyledTableRow component="th" scope="row">{user.date ? moment(user.date).format('DD.MM.YYYY HH:mm') : ''}</StyledTableRow>
                      <StyledTableRow component="th" scope="row">
                        <div>
                          {user.create_user?.first_name} {user.create_user?.last_name}
                        </div>
                      </StyledTableRow>
                      <StyledTableRow>{user.nominal_department || ''}</StyledTableRow>
                      <StyledTableRow>{user.project || ''}</StyledTableRow>
                      <StyledTableRow>{user.categoryTree || ''}</StyledTableRow>
                      <StyledTableRow>{user.supervisor ?
                        <div>
                          {user.supervisor.first_name} {user.supervisor.last_name}
                        </div>
                        : ''}
                      </StyledTableRow>
                      <StyledTableRow align="right" width="8%">
                        <Tooltip
                          title={
                            user.staff
                              ? renderDescription(user.description)
                              : renderStaffInfo(user.name, user.contact_number, user.description)
                          }
                          placement="right">
                          {user.staff ? (
                            <CheckCircleIcon sx={{ cursor: "pointer" }} />
                          ) : (
                            <DoNotDisturbOnIcon sx={{ cursor: "pointer" }} />
                          )}
                        </Tooltip>
                      </StyledTableRow>
                      <StyledTableRow align="right">
                        <Chip
                          onClick={access.expenses_get === 3 ? (event) => handleOpenMenu(event, user.id) : undefined}
                          label={user.status === 'fulfilled' ? 'approved' : user.status || ''}
                          sx={{
                            width: '70px',
                            color: 'var(--text-color)',
                            fontSize: '0.75rem',
                            height: '30px',
                            borderRadius: '16px',
                            cursor: 'pointer',
                            '& .MuiChip-avatar': {
                              width: '20px',
                              height: '20px',
                              fontSize: '0.75rem'
                            },
                            '& .MuiChip-label': {
                              padding: '0 8px'
                            }
                          }}
                          variant="outlined" />
                      </StyledTableRow>
                      <StyledTableRow align="right">{user.amount || ''}</StyledTableRow>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={expensesRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            {statusArray
              .map((stat: any) => (
                <MenuItem
                  key={stat.id}
                  onClick={() => handleSelectStatus(stat.title)}>
                  {`${stat.title || ''}`}
                </MenuItem>
              ))}
          </Menu>
          {openDel && <DeleteArrayAll
            open={openDel}
            setOpen={setOpenDel}
            refresh={refreshExpenses}
            selectedArrayId={selected}
            setSelected={setSelected}
            wordMenu={'expenses'}
            pd={'expenses'}
          />}
          {openStatus && <StatusArrayAll
            open={openStatus}
            setOpen={setOpenStatus}
            refresh={refreshExpenses}
            selectedArrayId={selected}
            setSelected={setSelected}
            wordMenu={wordStatus}
          />}
        </div>
      ) : (
        <div className='text-center text-[var(--text-color)]'>{t('No expenses available')}</div>
      )}
    </>
  );
};

export default ExpensesTable;