import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { t } from 'i18next';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import DeleteArrayAll from '../modal/DeleteArrayAll';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableCheck = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '0 0 0 8px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableSort = styled(TableSortLabel)(({ theme }) => ({
  fontSize: 14,
}));

interface ReportsTableProps {
  users: any;
  usersSuper: any;
  handleShow: any;
  handleShowDelete: any;
  selectedRoleFilter: any;
  access: any;
  baseUrl: string;
  viewLastLogin: boolean;
  viewContractTitle: boolean;
  viewJobTitle: boolean;
  refreshUsers: any;
  selected: any;
  setSelected: any;
  roles: any;
  refreshRoleAccept: any;
}

const UsersTable: React.FC<ReportsTableProps> = ({
  users,
  usersSuper,
  handleShow,
  handleShowDelete,
  selectedRoleFilter,
  access,
  baseUrl,
  viewLastLogin,
  viewContractTitle,
  viewJobTitle,
  refreshUsers,
  selected,
  setSelected,
  roles,
  refreshRoleAccept }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const paginatedUsers = users
    .filter((user: any) => selectedRoleFilter === null || user.role?.id === selectedRoleFilter)
    .sort((a: any, b: any) => {
      const compareValueA = order === 'asc' ? a.first_name : b.first_name;
      const compareValueB = order === 'asc' ? b.first_name : a.first_name;
      return compareValueA.localeCompare(compareValueB);
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenMenu = (event: any, userId: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleOpenRoleMenu = (event: any, userId: any) => {
    setRoleMenuAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleCloseRoleMenu = () => {
    setRoleMenuAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleSelectSupervisor = async (supervisorId: any) => {
    try {
      const updatedSupervisorId = supervisorId === -1 ? null : supervisorId;
      await axios.put(`updateUser/${selectedUserId}`, { supervisorId: updatedSupervisorId });
      refreshUsers();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const handleSelectRole = async (roleId: any) => {
    try {
      await axios.put(`updateUser/${selectedUserId}`, { roleId });
      refreshUsers();
      refreshRoleAccept();
    } catch (error) {
      console.error('Error updating role:', error);
    } finally {
      handleCloseRoleMenu();
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleShowDeleteAll = () => {
    setOpenDel(true);
  };

  useEffect(() => {
  }, [users])

  return (
    <>
      {Array.isArray(users) && users.length > 0 ? (
        <div className='mb-[10px] text-center'>
          <div
            className={`flex items-center ${selected.length > 0 ? 'py-[5px] px-[10px]' : ''}`}
            style={{
              backgroundColor: selected.length > 0 ? 'rgba(25, 118, 210, 0.12)' : 'transparent',
            }}>
            {selected.length > 0 ? (
              <div
                className='flex justify-between w-full'>
                {selected.length} {t('Selected')}
              </div>
            ) : (<></>)}
            {selected.length > 0 && (
              <div className='flex gap-[20px] items-center'>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                  onClick={() => handleShowDeleteAll()}
                />
              </div>
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < users.length}
                      checked={users.length > 0 && selected.length === users.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all equipment',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSort
                      active={orderBy === 'first_name'}
                      direction={orderBy === 'first_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('first_name')}>
                      {t('Name')}
                    </StyledTableSort>
                  </StyledTableCell>
                  {viewContractTitle && <StyledTableCell>{t('ContractType')}</StyledTableCell>}
                  {viewJobTitle && <StyledTableCell>{t('JobTitle')}</StyledTableCell>}
                  <StyledTableCell>{t('Email')}</StyledTableCell>
                  <StyledTableCell>{t('Role')}</StyledTableCell>
                  <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                  {viewLastLogin && <StyledTableCell>{t('LastLogin')}</StyledTableCell>}
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user: any, index: any) => {
                  const supervisor = usersSuper.find((userObj: any) => userObj.id === user.supervisorId) || {};
                  return (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableCheck padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(user.id) !== -1}
                          onChange={(event: any) => handleClick(event, user.id)}
                        />
                      </StyledTableCheck>
                      <StyledTableRow component="th" scope="row">
                        <div className="flex gap-[10px] items-center cursor-pointer" onClick={() => handleShow(user)}>
                          {user.avatarUrl ? (
                            <div className='w-[30px] h-[30px] cursor-pointer rounded-full'>
                              <img className="w-full h-full object-cover rounded-full" src={`${baseUrl}${user.avatarUrl}`} alt="avatar" />
                            </div>
                          ) : (
                            <div className="flex justify-center items-center text-white w-[30px] h-[30px] text-[14px] rounded-[50%] uppercase" style={{ backgroundColor: `${user.role?.color}` }}>
                              {user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : 'NA'}
                            </div>
                          )}
                          <div className='flex gap-[5px] item-center flex-wrap'>
                            <div className="user-box-name">{user.first_name || ''}</div>
                            <div className="user-box last">{user.last_name || ''}</div>
                            <div className="user-box second">{user.second_name || ''}</div>
                          </div>
                        </div>
                      </StyledTableRow>
                      {viewContractTitle && <StyledTableRow>{user.contractType?.title || ''}</StyledTableRow>}
                      {viewJobTitle && <StyledTableRow>{user.job_title || ''}</StyledTableRow>}
                      <StyledTableRow>{user.email || ''}</StyledTableRow>
                      <StyledTableRow
                        onClick={(e) => handleOpenRoleMenu(e, user.id)}
                        style={{ color: user.role?.color, cursor: 'pointer' }}>
                        {user.role?.title || ''}
                      </StyledTableRow>
                      <StyledTableRow>
                        {user.supervisorId ? (
                          <Chip
                            onClick={(event) => handleOpenMenu(event, user.id)}
                            variant="outlined"
                            sx={{
                              color: 'var(--text-color)',
                              fontSize: '0.75rem',
                              height: '30px',
                              borderRadius: '16px',
                              cursor: 'pointer',
                              '& .MuiChip-avatar': {
                                width: '20px',
                                height: '20px',
                                fontSize: '0.75rem'
                              },
                              '& .MuiChip-label': {
                                padding: '0 8px'
                              }
                            }}
                            avatar={supervisor.avatarUrl
                              ?
                              <Avatar src={`${baseUrl}${supervisor.avatarUrl}`} />
                              :
                              <Avatar>{supervisor.first_name && supervisor.last_name ? `${supervisor.first_name[0]}${supervisor.last_name[0]}` : ''}</Avatar>}
                            label={`${supervisor.first_name || 'none'} ${supervisor.last_name || ''}`}
                          />
                        ) : (<div
                          className='cursor-pointer w-[40px]'
                          onClick={(event) => handleOpenMenu(event, user.id)}
                        >none</div>)}
                      </StyledTableRow>
                      {viewLastLogin && <StyledTableRow>{user.last_login ? moment(user.last_login).format('HH:mm DD.MM.YYYY') : ''}</StyledTableRow>}
                      {access && (
                        <StyledTableRow align="right">
                          <div className='flex gap-[7px] justify-end items-center'>
                            {access.users_do >= 2 && (
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleShow(user)}
                              />
                            )}
                            {access.users_do > 2 && (
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleShowDelete(user)}
                              />
                            )}
                          </div>
                        </StyledTableRow>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        <div className='text-center text-[var(--text-color)]'>{t('No users available')}</div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem
          key={-1}
          onClick={() => handleSelectSupervisor(-1)}>
          none
        </MenuItem>
        {usersSuper
          .sort((a: any, b: any) => a.first_name.localeCompare(b.first_name))
          .map((supervisor: any) => (
            <MenuItem
              key={supervisor.id}
              onClick={() => handleSelectSupervisor(supervisor.id)}>
              {`${supervisor.first_name || ''} ${supervisor.last_name || ''}`}
            </MenuItem>
          ))}
      </Menu>
      <Menu
        anchorEl={roleMenuAnchorEl}
        open={Boolean(roleMenuAnchorEl)}
        onClose={handleCloseRoleMenu}>
        {roles.map((role: any) => (
          <MenuItem key={role.id} onClick={() => handleSelectRole(role.id)}>
            {role.title}
          </MenuItem>
        ))}
      </Menu>
      {openDel && <DeleteArrayAll
        open={openDel}
        setOpen={setOpenDel}
        refresh={refreshUsers}
        selectedArrayId={selected}
        setSelected={setSelected}
        wordMenu={'users'}
        pd={'user'}
      />}
    </>
  );
};

export default UsersTable;