import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px 15px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px 15px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface ReportsTableProps {
  financeRow: any
}

const UsersSalaryTable: React.FC<ReportsTableProps> = ({ financeRow }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {financeRow.length > 0 ?
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t('StartDate')}</StyledTableCell>
                  <StyledTableCell>{t('EndDate')}</StyledTableCell>
                  <StyledTableCell align="right">{t('SalaryPerHours')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {financeRow
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((fin: any, index: any) => (
                    <TableRow
                      key={fin.dateId || index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: fin.isCurrent ? 'var(--active-row-color)' : 'inherit',
                      }}>
                      <StyledTableRow>{fin.startDate}</StyledTableRow>
                      <StyledTableRow>{fin.endDate}</StyledTableRow>
                      <StyledTableRow align="right">{fin.salary_perHour}</StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={financeRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>)
        : (
          <div className='text-center opacity-25 text-[14px]'>
            {t('empty')}
          </div>
        )}
    </>
  );
};

export default UsersSalaryTable;