import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';

interface SelectProps {
  selectedTasks: any;
  setSelectedTasks: any;
  test: any;
  taskOrTasks: any
}

const SelectTask: React.FC<SelectProps> = ({ selectedTasks, setSelectedTasks, test, taskOrTasks }) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const location = useLocation();

  const handleSelectChange = (selectedOptions: any) => {
    if (taskOrTasks) {
      setSelectedTasks(selectedOptions);
    } else {
      setSelectedTasks(selectedOptions ? [selectedOptions] : []);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (test) {
          const { data: categoryCheck } = await axios.get(`category/${test}`);
          const formattedTasks = categoryCheck.task
            .filter((task: any) => task.active)
            .map((task: any) => ({
              value: task.id,
              label: task.name,
            }));
          setTasks(formattedTasks);
        } else {
          setTasks([]);
        }
      } catch (e) { }
    })();
  }, [test]);

  useEffect(() => {
    setSelectedTasks([]);
  }, [test]);

  return (
    <Select
      defaultValue={tasks[0]}
      isMulti={taskOrTasks}
      isClearable
      name="tasks"
      placeholder={location.pathname === '/reports' ? `${t('AllTasks')}` : `${t('SelectTasksPoins')}`}
      value={taskOrTasks ? selectedTasks : selectedTasks.length > 0 ? selectedTasks[0] : null}
      onChange={handleSelectChange}
      options={tasks}
      className="basic-multi-select w-full bg-[var(--bg-input)]"
      classNamePrefix="select"
    />
  );
};

export default SelectTask;
