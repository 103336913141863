import { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AddClient from './modal/AddClient';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface ClientsProps {
  access: any
}

const Clients: React.FC<ClientsProps> = ({access}) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  // const [openAddClient, setOpenAddClient] = useState(false);
  // const [clientName, setClientName] = useState('');
  // const [clientLastName, setClientLastName] = useState('');
  // const [clientCompanyName, setClientCompanyName] = useState('');
  // const [clientPost, setClientPost] = useState('');
  // const [clientEmail, setClientEmail] = useState('');
  // const [clientCity, setClientCity] = useState('');
  // const [clientStreet, setClientStreet] = useState('');
  // const [clientPhone, setClientPhone] = useState('');
  // const [client, setClient] = useState([]);
  // const [filteredClient, setFilteredClient] = useState([]);
  // const [sortDirection, setSortDirection] = useState('asc');
  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // const handleShow = () => {
  //   setOpenAddClient(true);
  //   setClientName('');
  //   setClientLastName('');
  //   setClientCompanyName('');
  //   setClientPost('');
  //   setClientCity('');
  //   setClientStreet('');
  //   setClientEmail('');
  //   setClientPhone('')
  // }

  // const handleAddClients = async () => {
  //   try {

  //     if 

  //       (clientName.trim() &&
  //       clientLastName.trim() &&
  //       clientCompanyName.trim() &&
  //       clientPost.trim() &&
  //       clientCity.trim() &&
  //       clientStreet.trim() &&
  //       clientEmail.trim() &&
  //       clientPhone.trim() &&
        
  //       // below code checks post input for numeric

  //       /^\d+$/.test(clientPost.trim()) &&

  //       // below code checks phone input for numeric

  //       /^\d+$/.test(clientPhone.trim()) &&

  //       // below code checks email for @

  //       /@/.test(clientEmail.trim()) &&

  //       // below code checks that name is at least 2 characters

  //       clientName.trim().length >= 2 &&

  //       // below code checks that last name is at least 2 characters

  //       clientLastName.trim().length >= 2
        
  //       ) 

  //       {
  //       await axios.post('/client/add', {
  //         name: clientName,
  //         last_name: clientLastName,
  //         companyName: clientCompanyName,
  //         address_city: clientCity,
  //         address_street: clientStreet,
  //         address_postcode: Number(clientPost),
  //         phone: clientPhone,
  //         email: clientEmail
  //       });
  //       const response = await axios.get('client');
  //       setClient(response.data)
  //       setFilteredClient(response.data);
  //       setOpenAddClient(false);
        
  //       enqueueSnackbar('Client add', { variant: 'success' });
  //     }

  //     // below code, first account for an empty field

  //     else if (!clientName.trim() ||
  //             !clientLastName.trim() ||
  //             !clientCompanyName.trim() ||
  //             !clientPost.trim() ||
  //             !clientCity.trim() ||
  //             !clientStreet.trim() ||
  //             !clientEmail.trim() ||
  //             !clientPhone.trim()
  //             ) {
        
  //       return enqueueSnackbar('Please fill in all fields!', { variant: 'error' });

  //     }

  //     // below code, account for non-numeric post
      
  //     else if (/^\d+$/.test(clientPost.trim()) === false) {
  //       return enqueueSnackbar('Post must be numeric', { variant: 'error' });
  //     }

  //     // below code, account for non-numeric phone

  //     else if (/^\d+$/.test(clientPhone.trim()) === false) {
  //       return enqueueSnackbar('Phone must be numeric', { variant: 'error' });
  //     }

  //     // below code, account for '@' not present in email

  //     else if (/@/.test(clientEmail.trim()) === false) {
  //       return enqueueSnackbar('Email must contain "@"', { variant: 'error' });
  //     }

  //     // below code, account for too short name

  //     else if (clientName.trim().length < 2) {
  //       return enqueueSnackbar('Name is not long enough!', { variant: 'error' });
  //     }

  //     // below code, account for last name too short

  //     else if (clientLastName.trim().length < 2) {
  //       return enqueueSnackbar('Last name is not long enough!', { variant: 'error' });
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleFilterClients = (query: any) => {
  //   const filteredClients = client.filter((cl: any) =>
  //     cl.last_name.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setFilteredClient(filteredClients);
  // };

  // const sortClients = useCallback((direction: any) => {
  //   const sortedClients = [...filteredClient].sort((a: any, b: any) => {
  //     const nameA = a.last_name.toLowerCase();
  //     const nameB = b.last_name.toLowerCase();
  //     if (direction === 'asc') {
  //       if (nameA < nameB) return -1;
  //       if (nameA > nameB) return 1;
  //       return 0;
  //     } else {
  //       if (nameA > nameB) return -1;
  //       if (nameA < nameB) return 1;
  //       return 0;
  //     }
  //   });
  //   setFilteredClient(sortedClients);
  // }, [filteredClient]);

  // let blockClient = (client: any, index: number) => (
  //   <div className='flex justify-between'>
  //     <div className="flex gap-[10px] items-center">
  //       <div className='text-[14px]'>{index + 1}.</div>
  //       <div className="flex justify-center items-center text-white w-[30px] h-[30px] text-[16px] rounded-[50%] uppercase" style={{ backgroundColor: 'gray' }}>
  //         {client.name[0]}{client.last_name[0]}</div>
  //       <div className='flex gap-[5px] item-center flex-wrap'>
  //         <div className="user-box-name">{client.name}</div>
  //         <div className="user-box last">{client.last_name}</div>
  //       </div>
  //     </div>
  //     <div className='flex items-center'>
  //       <div className='flex gap-[10px] item-center'>
  //         <div className="text-center max-w-[150px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{client.companyName}</div>
  //         <div className="text-center max-w-[150px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{client.phone}</div>
  //         <div className="text-center max-w-[300px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{client.email}</div>
  //       </div>
  //     </div>
  //   </div>);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        // const { data: dataClient } = await axios.get('client')
        if (data) {
          // setClient(dataClient);
          // setFilteredClient(dataClient);
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch]);

  // useEffect(() => {
  //   if (client.length) {
  //     if (sortDirection === '') {
  //       setFilteredClient(client);
  //     } else {
  //       sortClients(sortDirection);
  //     }
  //   };
  // }, [sortDirection, client]);

  return (
    <>
     {auth && access.clients_get > 0 ?
            (<div>
              <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
                <div className='flex justify-center opacity-50'>{t('underDevelopment')}</div>
              </div>
            </div>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>)}
      {/* {auth && access.clients_get > 0 ?
        (<div>
          <div className="bg-[var(--bg-form)] w-full p-[20px] mb-[20px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div className='flex justify-between items-center gap-4 w-[55%]'>
                <button
                  className='w-[180px] border-none text-[18px] rounded-[5px]  py-[6px] cursor-pointer bg-[#1976d2] text-white hover:bg-[#176bbe]'
                  onClick={handleShow}>Create Client
                </button>
                <input
                  type="text"
                  className='w-full input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                  placeholder='fillter'
                  onChange={(e) => handleFilterClients(e.target.value)} />
              </div>
              <div className='flex gap-2 items-center'>
                <p className='text-[14px] font-semibold'>Sort by:</p>
                <select
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  onChange={(e) => setSortDirection(e.target.value)}>
                  <option value="">list</option>
                  <option value="asc">last name A-Z</option>
                  <option value="desc">last name Z-A</option>
                </select>
              </div>
            </div>
          </div>
          <div className='admin-box flex flex-col justify-start p-[20px] rounded-[5px]'>
            <h3 className='text-[18px] mb-4'>Clients List:</h3>
            <div>
              {filteredClient?.length ? (
                filteredClient.map((client: any, index: number) => (
                  <div key={client.id} className='bg-[var(--btn-user)] p-[10px] text-[14px] mb-1 items-center rounded-[10px]'>
                    {blockClient(client, index)}
                  </div>
                ))
              ) : (
                <div>none...</div>
              )}
            </div>
          </div>
          {openAddClient && <AddClient
            openAddClient={openAddClient}
            setOpenAddClient={setOpenAddClient}
            setClientName={setClientName}
            setClientLastName={setClientLastName}
            setClientCompanyName={setClientCompanyName}
            setClientPost={setClientPost}
            setClientCity={setClientCity}
            setClientStreet={setClientStreet}
            clientName={clientName}
            clientLastName={clientLastName}
            clientCompanyName={clientCompanyName}
            clientPost={clientPost}
            clientCity={clientCity}
            clientStreet={clientStreet}
            clientEmail={clientEmail}
            clientPhone={clientPhone}
            setClientPhone={setClientPhone}
            setClientEmail={setClientEmail}
            handleAddClients={handleAddClients} />}
        </div>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)} */}
    </>
  )
}

export default Clients
