import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store'
import ReportsTableAprove from './aproveComponents/ReportsTableAprove';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';

interface ApproveProps {
  access: any,
  refreshUserHeder: any
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  email: string;
  date_of_birth: Date;
  contract_start_date: Date;
  sex: string;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
  last_login: any;
}

const Approve: React.FC<ApproveProps> = ({
  access,
  refreshUserHeder }) => {
  const [reportPending, setReportsPending] = useState<any[]>([]);
  const [userId, setUserID] = useState();
  const [rows, setRows] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterType, setFilterType] = useState('user');
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [categoryOrList, setCategoryOrList] = useState<string>(localStorage.getItem('categoryOrList') || 'category');

  const updateRowsStatusChange = () => {
    const updatedRows = reportPending.map(item => {
      const startTime = moment(item.startTime);
      const endTime = moment(item.endTime);
      let duration = moment.duration(endTime.diff(startTime));
      if (duration.asMinutes() < 0) {
        duration = moment.duration(endTime.add(1, 'day').diff(startTime));
      }
      const diffInMinutes = endTime.diff(startTime, 'minutes');
      const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
      return {
        userId: item.userId,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        categoryid: item.categoryId,
        description: item.description,
        dateId: item.id,
        categoryTree: `${item.categoryTree}`,
        category: `${item.category.name}`,
        userName: `${item.user?.first_name} ${item.user?.last_name}`,
        superVisor: `${item.supervisor ? (`${item.supervisor?.first_name} ${item.supervisor?.last_name}`) : ''}`,
        tasks: item.tasks,
        can_approve: item.can_approve,
        allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`
      };
    });
    setRows(updatedRows);
  };

  const handleFilterChange = (event: any) => {
    if (event.target.value === 'user') {
      setSelectedUser('');
    }
    setFilterType(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem('categoryOrList', categoryOrList);
  }, [categoryOrList]);

  const updateRowsAfterStatusChange = useCallback(async () => {
    setLoading(true);
    try {
      const { data: supervisor } = await axios.get(`time-reports/${userId}`);
      setReportsPending(supervisor);
      updateRowsStatusChange();
    } catch (error) {
      console.error('Error updating rows after status change:', error);
    } finally {
      setLoading(false);
    }
  }, [userId, setReportsPending, updateRowsStatusChange]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('user');
        const { data: allUsers } = await axios.get('users');
        const { data: supervisorPending } = await axios.get(`time-reports/${data.id}`);
        if (data) {
          setUserID(data.id);
          dispatch(setAuth(true));
          setUsers(allUsers);
        }
        setReportsPending(supervisorPending);
      } catch (e) {
        dispatch(setAuth(false));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, location.pathname]);

  useEffect(() => {
    updateRowsStatusChange();
  }, [reportPending]);

  return (
    <div>
      <div
        className={`bg-[var(--bg-form)] w-full box-users gap-[5px] p-[15px] mb-[15px] rounded-[5px] max-h-[96vh] overflow-y-auto relative ${rows.length > 0 && "pt-[65px]"}`}>
        {rows.length > 0 &&
          <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
            <div className="flex gap-4  my-[10px] justify-start items-center w-[80%]">
              <h3 className='text-[18px]'>{t('ApproveList')}:</h3>
            </div>
            <div className='flex justify-end items-center'>
              <div className='flex items-center gap-[5px] justify-end'>
                <p className='text-[16px] font-semibold whitespace-nowrap'>{t('SortBy')}:</p>
                <select
                  value={filterType}
                  className='text-[15px] timesheet-edit-input bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  onChange={handleFilterChange}>
                  <option value="user">user</option>
                  <option value="category">category</option>
                </select>
                {filterType === 'category' ? (
                  <select
                    onChange={(e) => setSelectedUser(e.target.value)}
                    value={selectedUser || ''}
                    className='text-[15px] timesheet-edit-input bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'>
                    <option value="">All Users</option>
                    {users
                      ?.sort((a, b) => {
                        const nameA = a.first_name.toLowerCase();
                        const nameB = b.first_name.toLowerCase();
                        return nameA.localeCompare(nameB);
                      })
                      .map((user: User) => (
                        <option key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select
                    className='text-[15px] timesheet-edit-input bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                    value={categoryOrList || ''}
                    onChange={(e) => setCategoryOrList(e.target.value)}>
                    <option value="category">category</option>
                    <option value="list">list</option>
                  </select>
                )}
                <select
                  id="sortDirection"
                  value={sortDirection}
                  onChange={(e) => setSortDirection(e.target.value)}
                  className="text-[15px] timesheet-edit-input bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]">
                  <option value="asc">{t('week')} ⇈</option>
                  <option value="desc">{t('week')} ⇊</option>
                </select>
              </div>
            </div>
          </div>
        }
        {rows.length > 0 || !loading ? (
          auth && access.approve_get > 0 ? (
            <ReportsTableAprove
              rows={rows}
              updateRowsAfterStatusChange={updateRowsAfterStatusChange}
              setLoading={setLoading}
              loading={loading}
              refreshUserHeder={refreshUserHeder}
              filterType={filterType}
              sortDirection={sortDirection}
              setFilterType={setFilterType}
              selectedUser={selectedUser}
              categoryOrList={categoryOrList} />
          ) :
            (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>)
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  )
}

export default Approve