import React, { useState, useEffect, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';

interface TemporaryDrawerProps {
  setShowModalToils: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUserToils: any;
  refreshUsers: any;
}

export default function UserToils({ setShowModalToils, selectedUserToils, refreshUsers }: TemporaryDrawerProps) {
  const [toilsNum, setToilsNum] = useState('');
  const [descriptions, setDescriptions] = useState('');
  const [userInfo, setUserInfo] = useState(0);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
          setUserInfo(data.roleId);
        }

      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch, userInfo]);


  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!toilsNum) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
      return;
    }

    try {
      await axios.post('toils/add', {
        date: new Date(),
        total_sum: Number(toilsNum),
        description: descriptions,
        userId: selectedUserToils.id
      });
      refreshUsers();
      setShowModalToils(false);
      enqueueSnackbar('Add successfully', { variant: 'success' });
    } catch (error: any) {
      console.error('Error:', error);
      enqueueSnackbar('Add failed. Please try again.', { variant: 'error' });
    }
  }

  return (
    <Box sx={{
      width: 400,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[10px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold'>{t('AddToils')}</h1>
          <hr className='hr mt-[10px] mb-[10px]' />
          <form className='flex flex-col justify-evenly flex-grow' id='reg-user' onSubmit={submit}>
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('AddInformation')}</h2>
            <input
              placeholder={t('removeTOIL')}
              type="number"
              step={1}
              pattern="[0-9]"
              inputMode="numeric"
              value={toilsNum}
              onChange={e => {
                const input = e.target.value;
                const cleanInput = input.replace(/[^0-9.-]/g, '');
                setToilsNum(cleanInput);
              }} />
            <input placeholder={t('discriptions')} type="text" onChange={e => setDescriptions(e.target.value)} />
            <hr className='hr mt-1 mb-[15px]' />
            <div className='flex gap-[20px] justify-center items-center'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                type='submit'>
                {t('Add')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={(e) => { e.preventDefault(); setShowModalToils(false) }}>
                {t('Cancel')}
              </Button>
            </div>
          </form>
        </div>
      </List>
    </Box>
  )
}