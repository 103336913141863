import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { t } from 'i18next';
import {
  Menu,
  MenuItem,
  // Button 
} from '@mui/material';
import axios from 'axios';
import moment from "moment";
// import { useSnackbar } from 'notistack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px 15px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px 15px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableSort = styled(TableSortLabel)(({ theme }) => ({
  fontSize: 14,
}));

interface ReportsTableProps {
  users: any,
  handleShow: any,
  selectedContractTypeFilter: any,
  access: any,
  baseUrl: string,
  contractType: any,
  refreshUsers: any,
}

const FinanceTable: React.FC<ReportsTableProps> = ({
  users,
  handleShow,
  selectedContractTypeFilter,
  access,
  baseUrl,
  contractType,
  refreshUsers
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState(null);
  // const [roleMenuHoursEl, setRoleMenuHoursEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  // const [newPrice, setNewPrice] = useState('');
  // const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const paginatedUsers = users
    .filter((user: any) => selectedContractTypeFilter === null || user.contractTypeId === selectedContractTypeFilter)
    .sort((a: any, b: any) => {
      const compareValueA = order === 'asc' ? a.first_name : b.first_name;
      const compareValueB = order === 'asc' ? b.first_name : a.first_name;
      return compareValueA.localeCompare(compareValueB);
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleOpenMenu = (event: any, userId: any, salaryPerHour: any) => {
  //   setRoleMenuHoursEl(event.currentTarget);
  //   setSelectedUserId(userId);
  //   setNewPrice(salaryPerHour || 0);
  // };

  // const handleCloseMenu = () => {
  //   setRoleMenuHoursEl(null);
  //   setSelectedUserId(null);
  //   setNewPrice('');
  // };

  // const handleInputChange = (e: any) => {
  //   let inputValue = e.target.value;
  //   inputValue = inputValue.replace(/[^0-9.,]/g, ''); 
  //   inputValue = inputValue.replace(',', '.');
  //   const parts = inputValue.split('.');
  //   if (parts.length > 2) {
  //     inputValue = parts[0] + '.' + parts.slice(1).join('');
  //   }

  //   setNewPrice(inputValue);
  // };

  // const handleSavePrice = async () => {
  //   const price = parseFloat(newPrice.replace(',', '.')); 
  //   if (isNaN(price) || price <= 0) {
  //     enqueueSnackbar('Invalid price', { variant: 'error' });
  //     return;
  //   }
  //   try {
  //     await axios.put(`updateUser/${selectedUserId}`, { salary_perHour: price });
  //     refreshUsers();
  //     enqueueSnackbar('Updated successfully', { variant: 'success' });
  //   } catch (error) {
  //     console.error('Error saving:', error);
  //     enqueueSnackbar('Updated error', { variant: 'error' });
  //   } finally {
  //     handleCloseMenu();
  //   }
  // };

  const handleOpenContractTypeMenu = (event: any, userId: any) => {
    setRoleMenuAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleCloseContractTypeMenu = () => {
    setRoleMenuAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleContractTyprRole = async (contractId: any) => {
    try {
      await axios.put(`updateUser/${selectedUserId}`, { contractTypeId: contractId });
      refreshUsers();
    } catch (error) {
      console.error('Error updating contract:', error);
    } finally {
      handleCloseContractTypeMenu();
    }
  };

  useEffect(() => {
  }, [users])

  return (
    <>
      {Array.isArray(users) && users.length > 0 ? (
        <div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <StyledTableSort
                      active={orderBy === 'first_name'}
                      direction={orderBy === 'first_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('first_name')}>
                      {t('Name')}
                    </StyledTableSort>
                  </StyledTableCell>
                  <StyledTableCell>{t('ContractType')}</StyledTableCell>
                  <StyledTableCell>{t('ContractStartDate')}</StyledTableCell>
                  <StyledTableCell>{t('PerWeek')}</StyledTableCell>
                  <StyledTableCell>{t('CostPerHour')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user: any, index: any) => {
                  return (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow component="th" scope="row">
                        <div className="flex gap-[10px] items-center cursor-pointer" onClick={() => handleShow(user)}>
                          {user.avatarUrl ? (
                            <div className='w-[30px] h-[30px] cursor-pointer rounded-full'>
                              <img className="w-full h-full object-cover rounded-full" src={`${baseUrl}${user.avatarUrl}`} alt="avatar" />
                            </div>
                          ) : (
                            <div className="flex justify-center items-center text-white w-[30px] h-[30px] text-[14px] rounded-[50%] uppercase" style={{ backgroundColor: `${user.role?.color}` }}>
                              {user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : 'NA'}
                            </div>
                          )}
                          <div className='flex gap-[5px] item-center flex-wrap'>
                            <div className="user-box-name">{user.first_name || ''}</div>
                            <div className="user-box last">{user.last_name || ''}</div>
                            <div className="user-box second">{user.second_name || ''}</div>
                          </div>
                        </div>
                      </StyledTableRow>
                      <StyledTableRow
                        onClick={(e) => handleOpenContractTypeMenu(e, user.id)}
                        style={{ cursor: 'pointer' }}>
                        {user.contractType ? user.contractType.title : ''}</StyledTableRow>
                      <StyledTableRow>{user.contract_start_date ? moment(user.contract_start_date).format('DD.MM.YYYY') : ''}</StyledTableRow>
                      <StyledTableRow>{user.hoursPerWeek ? user.hoursPerWeek : 0}</StyledTableRow>
                      <StyledTableRow
                      // onClick={(e) => handleOpenMenu(e, user.id, user.salary_perHour)}
                      // style={{ cursor: 'pointer' }}
                      >
                        {user.salary_perHour ? user.salary_perHour : 0}
                      </StyledTableRow>
                      {access && (
                        <StyledTableRow align="right">
                          <div className='flex gap-[7px] justify-end items-center'>
                            {access.users_do >= 2 && (
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleShow(user)}
                              />
                            )}
                          </div>
                        </StyledTableRow>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        <div className='text-center text-[var(--text-color)]'>{t('No users available')}</div>
      )}
      <Menu
        anchorEl={roleMenuAnchorEl}
        open={Boolean(roleMenuAnchorEl)}
        onClose={handleCloseContractTypeMenu}>
        {contractType.map((contr: any) => (
          <MenuItem key={contr.id} onClick={() => handleContractTyprRole(contr.id)}>
            {contr.title}
          </MenuItem>
        ))}
      </Menu>
      {/* <Menu
        anchorEl={roleMenuHoursEl}
        open={Boolean(roleMenuHoursEl)}
        onClose={handleCloseMenu}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
        <div className="flex gap-[10px] items-center px-[10px] max-w-[200px] text-[var(--text-color)]">
          <input
            className='w-full bg-[var(--bg-input)] p-[4px] rounded-[5px] edit-reports-input'
            type="text"
            value={newPrice}
            onChange={(e) => handleInputChange(e)} />
          <Button onClick={handleSavePrice}
            variant="contained"
            sx={{
              color: 'white !important',
              fontSize: 12
            }}
          >Save</Button>
        </div>
      </Menu> */}
    </>
  );
};

export default FinanceTable;