import { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import enGB from 'date-fns/locale/en-GB';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TreeViewComponent from './util/TreeViewComponent';
import SelectTask from './timesheetsComp/SelectTask';
import { transformToHierarchy } from './util/tree';
import { useSnackbar } from 'notistack';
import AddTemplate from './modal/AddTemplate';
import TemplateView from './templateComponents/TemplateView';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

registerLocale('en-GB', enGB);

interface TemplateProps {
  access: any,
  usersSuperId: any,
  is24HourFormat: any,
  taskOrTasks: any,
  refreshSelectTemplate: any,
  setAllTemplate: any,
  allTemplate: any,
  allTemplateSelect: any,
  setAllTemplateSelect: any,
  refresh: any
}

const Template: React.FC<TemplateProps> = ({
  access,
  is24HourFormat,
  usersSuperId,
  taskOrTasks,
  refreshSelectTemplate,
  setAllTemplate,
  allTemplate,
  allTemplateSelect,
  setAllTemplateSelect,
  refresh }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [timeFrom, setTimeFrom] = useState(moment().hour(9).minute(0).toDate());
  const [timeTo, setTimeTo] = useState(moment().hour(9).minute(0).toDate());
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [test, setTest] = useState<any>();
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [reports, setReports] = useState<any[]>([]);
  const [tasks, setTasks] = useState();
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [selectedDay, setSelectedDay] = useState('1');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [openAdd, setOpenAdd] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const dispatch = useDispatch();

  const handleChangeTemplate = (e: any) => {
    const selectedValue = e.target.value;
    setSelectedTemplate(selectedValue);
    const selectedTemp = allTemplateSelect.find((temp: any) => temp.temp_group_number === selectedValue);
    if (selectedTemp) {
      setSelectedTemplateName(selectedTemp.temp_name);
    }
  }

  const handleAddorCreate = (item: string) => {
    setOpenAdd(true);
    setSelectedTemplate(item)
  }

  // change format date to string 
  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  //show category
  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  //add date
  const handleDateChange = (e: any) => {
    const newSelectedDay = e.target.value;
    setSelectedDay(newSelectedDay);
  };

  //add time from
  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  //add time to
  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  //category select
  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      setTest(nodeId)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
    } else {
      console.log('Category not found');
    }
  };

  // add template
  const handleAddReport = () => {

    if ((moment(timeFrom).format('HH:mm:ss')) >= (moment(timeTo).format('HH:mm:ss'))) {
      enqueueSnackbar('Invalid time range: TimeFrom must be less than TimeTo', { variant: 'error' });
      return;
    }

    if (selectedCategoryId.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }

    if (categoryName.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }

    if (selectedTasks.length === 0) {
      enqueueSnackbar('Invalid task: You did not select a task', { variant: 'error' });
      return;
    }

    const filteredTemplates = allTemplate.filter(
      (template: any) => template.temp_group_number === Number(selectedTemplate)
    );

    const newReport = {
      selectedDay,
      startTime: formatTime(timeFrom),
      endTime: formatTime(timeTo),
      categoryId: selectedCategoryId[0],
      tasksId: selectedTasks,
      description
    };

    setReports((prevReports) => [...prevReports, newReport]);
    setDescription('');

    if (newReport) {
      const isTimeConflict = filteredTemplates.some((template: any) => {
        const templateStartTime = moment(template.startTime, 'HH:mm').set('day', Number(template.day_number));
        const templateEndTime = moment(template.endTime, 'HH:mm').set('day', Number(template.endDay_number));
        const existingStartTime = moment(newReport.startTime, 'HH:mm').set('day', Number(newReport.selectedDay));
        const existingEndTime = moment(newReport.endTime, 'HH:mm').set('day', Number(newReport.selectedDay));
        const isSameDay = template.day_number === Number(newReport.selectedDay);
        const isTimeOverlap = templateStartTime.isBefore(existingEndTime) && templateEndTime.isAfter(existingStartTime);

        return isSameDay && isTimeOverlap;
      });

      if (isTimeConflict) {
        setReports([]);
        enqueueSnackbar('Time conflict: The selected time is busy', { variant: 'error' });
        return;
      }
    }

    handleAddorCreate(selectedTemplate);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
        }
        const { data: categoryData } = await axios.get('category');

        const { data: taskData } = await axios.get('task');

        if (usersSuperId === 'none') {
          const { data: templateData } = await axios.get('template/user');
          const { data: template } = await axios.get('template/user/group');
          setAllTemplateSelect(template);
          setAllTemplate(templateData);
        } else {
          const { data: templateData } = await axios.post('template/user/sub', { userId: usersSuperId });
          const { data: template } = await axios.post('template/user/group/sub', { userId: usersSuperId });
          setAllTemplateSelect(template);
          setAllTemplate(templateData);
        }
        setTasks(taskData);
        setAllCategory(categoryData);
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname, reports]);

  return (
    <>
      {/* <Breadcrums locationName={locationName} /> */}
      {auth && access.timesheets_get > 0 ? (
        <div>
          <div className='flex gap-[15px] media-flex-wrap w-full items-start'>
            <div
              className={`relative box-users flex flex-col justify-start p-[15px] rounded-[5px] pb-[20px] w-[50%] flex-grow media-ter ${allTemplate && allTemplate.length > 0 ? 'pt-[65px]' : ''}`}>
              {allTemplate && allTemplate.length > 0 ? (
                <>
                  <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                    <div className="flex gap-4 my-[10px] justify-start items-center w-[80%]">
                      <h3 className='text-[18px]'>{t('MyTemplates')}:</h3>
                    </div>
                  </div>
                  <TemplateView
                    rows={allTemplate}
                    allCategory={allCategory}
                    tasks={tasks}
                    is24HourFormat={is24HourFormat}
                    refresh={refresh}
                    usersSuperId={usersSuperId}
                    refreshSelectTemplate={refreshSelectTemplate}
                    taskOrTasks={taskOrTasks}>
                  </TemplateView>
                </>) : (
                <div
                  className='flex items-center justify-center'>
                  <div
                    className='opacity-45'>{t('Empty')}</div>
                </div>
              )}
            </div>
            <div className="bg-[var(--bg-form)] w-full xl:max-w-[400px] flex justify-left items-center gap-[5px] p-[15px] mb-[15px] rounded-[5px] flex-wrap relative pt-[65px] filter-block">
              <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                <div className="flex gap-4 my-[10px] justify-start items-center w-[80%]">
                  <h3 className='text-[18px]'>{t('AddTempate')}:</h3>
                </div>
                <div className='flex gap-[5px] justify-end items-center w-full'>
                  <label className='text-[10px]'>{t('Tempate')}:</label>
                  <select
                    className='text-[15px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    onClick={(e) => handleChangeTemplate(e)}>
                    <option value="">{t('CreateNew')}</option>
                    {allTemplateSelect.map((temp: any, index: number) => (
                      <option
                        key={index}
                        value={temp.temp_group_number}>{temp.temp_name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='flex flex-col mb-[5px] w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Date')}:</label>
                <div className='flex gap-[5px]'>
                  <div className="flex w-full">
                    <select
                      onChange={(e) => handleDateChange(e)}
                      className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px] w-full'>
                      <option value="1">{t('Monday')}</option>
                      <option value="2">{t('Tuesday')}</option>
                      <option value="3">{t('Wednesday')}</option>
                      <option value="4">{t('Thursday')}</option>
                      <option value="5">{t('Friday')}</option>
                      <option value="6">{t('Saturday')}</option>
                      <option value="7">{t('Sunday')}</option>
                    </select>
                  </div>
                  <div className='flex w-[180px]'>
                    <DatePicker
                      selected={timeFrom}
                      onChange={(time: any) => handleTimeFromChange(time)}
                      className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                      placeholderText="Select Time"
                      showTimeSelect
                      dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                      timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                      showTimeSelectOnly
                      timeIntervals={15} />
                  </div>
                  <div className='flex w-[180px]'>
                    <DatePicker
                      selected={timeTo}
                      onChange={(time: any) => handleTimeToChange(time)}
                      className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                      showTimeSelect
                      dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                      timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                      showTimeSelectOnly
                      timeIntervals={15} />
                  </div>
                </div>
              </div>
              <div className='mb-[7px] flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Category')}:</label>
                <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-full flex-grow text-[16px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
                  onClick={handleClickCategoryBox}>
                  <div className="width-fix">{categoryName.length ? categoryName : <div className='text-[#80807a] text-[16px]'>{t('SelectCategory')}</div>}</div>
                  <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50 ' /></div>
                  {isCategoryListVisible && (
                    <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] py-[5px]"
                      onClick={handleCategoryListClick}>
                      <TreeViewComponent
                        nodes={category}
                        onNodeSelect={handleNodeSelect}
                        updateTree={''}
                        openCheckModal={openCheckModal}
                        selectedCategoryId={''} />
                    </div>
                  )}
                </div>
              </div>
              <div className='flex flex-col mb-[5px] w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Task')}:</label>
                <div
                  className='w-full relative flex-grow'>
                  <SelectTask
                    test={test}
                    setSelectedTasks={setSelectedTasks}
                    selectedTasks={selectedTasks}
                    taskOrTasks={taskOrTasks} />
                </div>
              </div>
              <div className='flex flex-col mb-[5px] w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Description')}:</label>
                <div
                  className='w-full relative flex-grow'>
                  <textarea
                    className='w-full input-timesheet text-[var(--text-color)] text-[16px] bg-[var(--bg-input)] p-[5px] rounded-[5px]'
                    rows={2}
                    value={description}
                    placeholder={t('DescriptionPoins')}
                    onChange={(e) => setDescription(e.target.value)} />
                </div>
              </div>
              <hr className='hr mb-[10px] w-full' />
              <div className='flex items-center justify-center flex-grow w-full'>
                <Button
                  variant="contained"
                  endIcon={<PlaylistAddIcon
                    sx={{ color: 'white !important' }} />}
                  sx={{
                    padding: '5px 20px',
                    fontSize: 14,
                    color: 'white !important',
                    width: '100%'
                  }}
                  onClick={handleAddReport}>
                  {selectedTemplate === '' ? `${t('CreateNew')}` : `${t('AddTo')} "${selectedTemplateName}"`}
                </Button>
              </div>
            </div>
          </div>
          {openAdd &&
            <AddTemplate
              openAdd={openAdd}
              setOpenAdd={setOpenAdd}
              setCheckOpenModal={setCheckOpenModal}
              setReports={setReports}
              reports={reports}
              selectedTemplate={selectedTemplate}
              selectedTemplateName={selectedTemplateName}
              usersSuperId={usersSuperId}>
            </AddTemplate>}
        </div >
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default Template
