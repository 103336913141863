import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import DateRangePicker from './reportsComponents/DateRangePicker';
import UserReportTable from './userReportComponents/UserReportTable';

const UsersReports = () => {
  const [reports, setReports] = useState([]);
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const rows = reports
    .map((item: any) => {
      const startTime = moment(item.startTime);
      const endTime = moment(item.endTime);
      let duration = moment.duration(endTime.diff(startTime));
      if (duration.asMinutes() < 0) {
        duration = moment.duration(endTime.add(1, 'day').diff(startTime));
      }
      const diffInMinutes = endTime.diff(startTime, 'minutes');
      const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
      return {
        userId: item.userId,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        categoryid: item.categoryId,
        description: item.description,
        dateId: item.id,
        tasks: item.tasks,
        user: item.user,
        categoryTree: `${item.categoryTree}`,
        userName: `${item.user.first_name} ${item.user.last_name}`,
        allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`,
        status: item.status
      };
    });

  // const aggregateDataByWeek = (data: any[]): any[] => {
  //   const aggregatedData: any[] = [];
  //   const userIdsProcessed: any = {};

  //   data.forEach((row) => {
  //     if (!userIdsProcessed[row.userId]) {
  //       const userRows = data.filter(item => item.userId === row.userId);
  //       const weeks: any[] = [];

  //       userRows.forEach((item) => {
  //         const weekIndex = moment(item.date).startOf('isoWeek').format('YYYY-MM-DD');
  //         const existingWeek = weeks.find((week) => week.weekIndex === weekIndex);

  //         if (existingWeek) {
  //           existingWeek.totalTime += parseFloat(item.allTime.replace(':', '.'));
  //           existingWeek.days.push(item);
  //         } else {
  //           weeks.push({
  //             weekIndex,
  //             startDate: moment(item.date).startOf('isoWeek').format('YYYY-MM-DD'),
  //             endDate: moment(item.date).endOf('isoWeek').format('YYYY-MM-DD'),
  //             totalTime: parseFloat(item.allTime.replace(':', '.')),
  //             days: [item],
  //           });
  //         }
  //       });

  //       aggregatedData.push({
  //         userId: row.userId,
  //         weeks,
  //       });

  //       userIdsProcessed[row.userId] = true;
  //     }
  //   });

  //   return aggregatedData;
  // };

  //get all data and check user
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('user');
        const { data: allReports } = await axios.post('time-reports/reports', {
          startTime: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          status: ['fulfilled']
        });
        if (data) {
          dispatch(setAuth(true));
        }
        setReports(allReports);
      } catch (e) {
        dispatch(setAuth(false));
      }
    })();
  }, [dispatch, location.pathname, startDate, endDate,]);

  return (
    <>
      {auth ? (
        <div className="bg-[var(--bg-form)] w-full box-users gap-[5px] px-[15px] py-[10px] rounded-[5px] relative pt-[55px]">
          <div >
            <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
              <div className="flex gap-4 my-[10px] justify-start items-center w-[80%]">
                <h3 className='text-[18px]'>{t('Short Report')}:</h3>
              </div>
              <div className='flex gap-[10px] justify-end items-center'>
                <p className='text-[16px]'>{t('Period')}:</p>
                <div className='flex justify-normal items-center'>
                  <DateRangePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate} />
                </div>
              </div>
            </div>
            {reports.length > 0 ? (
              <div className='mt-[20px]'>
                <UserReportTable
                  rows={rows} />
              </div>
            ) : (
              <div className='text-center opacity-20'>emty</div>
            )}
          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default UsersReports;