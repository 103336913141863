import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import Button from '@mui/material/Button';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddExpenses from './modal/AddExpenses'
import Drawer from '@mui/material/Drawer';
import ExpensesTable from './expensesComponents/ExpensesTable';
import DateRangePicker from './reportsComponents/DateRangePicker';
import moment from 'moment';

interface ExpensesProps {
  access: any,
  is24HourFormat: any
}

const Expenses: React.FC<ExpensesProps> = ({ access, is24HourFormat }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  });
  const [expensesRow, setExpensesRow] = useState([]);
  const [expensesRowOriginal, setExpensesRowOriginal] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState('');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [allUsers, setAllUsers] = useState({});
  const [userId, setUserId] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        const { data: allUsers } = await axios.get('users');
        const reportRequestData: any = {
          startTime: moment(startDate).startOf('day').format('YYYY-MM-DD'),
          endTime: moment(endDate).endOf('day').format('YYYY-MM-DD')
        };
        const { data: Expenses } = await axios.post('expenses', reportRequestData);
        if (data) {
          setUserId(data.id);
          setUserInfo(`${data.first_name} ${data.last_name}`);
          dispatch(setAuth(true));
        }
        if (Expenses) {
          setExpensesRow(Expenses);
          setExpensesRowOriginal(Expenses);
        }
        if (allUsers) {
          setAllUsers(allUsers);
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname, startDate, endDate]);

  const filteredExpenses = selectedUserId === 'all users' ? expensesRow : expensesRow.filter((row: any) => row.create_userId === Number(selectedUserId));

  //refresh expenses row
  const refreshExpenses = async () => {
    try {
      const reportRequestData: any = {
        startTime: moment(startDate).startOf('day').format('YYYY-MM-DD'),
        endTime: moment(endDate).endOf('day').format('YYYY-MM-DD')
      };
      const { data: Expenses } = await axios.post('expenses', reportRequestData);
      if (Expenses) {
        setExpensesRow(Expenses);
        setExpensesRowOriginal(Expenses);
      }
    } catch (error: any) {
      console.log('error expenses');
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUserId = event.target.value;
    setSelectedUserId(selectedUserId);
  };

  useEffect(() => {
    setSelectedUserId('all users');
  }, []);

  return (
    <>
      {auth && access.expenses_get > 0 ?
        (
          <>
            <div className="bg-[var(--bg-form)] w-full px-[15px] py-[10px] mb-[15px] rounded-[5px]">
              <div className='flex justify-between items-center'>
                <div className="flex gap-3 justify-start items-center w-full whitespace-nowrap">
                  <Button
                    //open addExpenses
                    variant="contained"
                    endIcon={<AddCardIcon
                      sx={{ color: 'white !important' }} />}
                    sx={{
                      padding: '5px 20px',
                      fontSize: 14,
                      color: 'white !important'
                    }}
                    onClick={() => setShowModalAdd(true)}
                  >
                    {t('AddExpenses')}
                  </Button>
                  <input
                    //fillter expenses 
                    type="text"
                    className='w-[60%] text-[16px] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                    placeholder={t('fillter')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const searchText = e.target.value.toLowerCase();
                      const filteredUsers = expensesRowOriginal.filter((row: any) =>
                        row.create_user?.first_name.toLowerCase().includes(searchText) ||
                        row.create_user?.last_name.toLowerCase().includes(searchText) ||
                        row.supervisor?.first_name.toLowerCase().includes(searchText) ||
                        row.supervisor?.last_name.toLowerCase().includes(searchText)
                      );
                      setExpensesRow(filteredUsers);
                    }}
                  />
                </div>
                <div className='flex items-center justify-end gap-[10px] w-full'>
                  <p className='media-one text-[14px] font-semibold'>{t('Period')}:</p>
                  <div className='flex justify-normal items-center'>
                    <DateRangePicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate} />
                  </div>
                </div>
              </div>
            </div>
            <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px] mb-3 relative pt-[55px]'>
              <div className='admin-box-users'>
                <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                  <div className="flex gap-4 my-[10px] justify-start items-center w-[80%]">
                    <h3 className='text-[18px]'>{t('ExensesList')}:</h3>
                  </div>
                  <div className='flex items-center justify-end gap-[10px] w-full'>
                    <p className='media-one text-[14px] font-semibold'>{t('SortBy')}:</p>
                    <div className='flex'>
                      <select
                        value={selectedUserId}
                        onChange={handleChange}
                        className='input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] w-[200px] p-[4px] rounded-[5px]'>
                        {selectedUserId === 'none' ? (
                          <option value="none" disabled>Select user...</option>
                        ) : ('')}
                        {access.description === -1 ? '' : <option value={userId}>{userInfo}</option>}
                        {Object.values(allUsers)
                          .sort((a: any, b: any) =>
                            a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name)
                          )
                          .map((user: any) => (
                            <option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                          ))}
                        <option value="all users">All users</option>
                      </select>
                    </div>
                    <div>
                      <select
                        // select role 
                        className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                        value={selectedStatusFilter !== null ? selectedStatusFilter : 'all'}
                        onChange={(e) => setSelectedStatusFilter(e.target.value === 'all' ? null : (e.target.value))}>
                        <option value="all">all status</option>
                        <option value="pending">pending</option>
                        <option value="rejected">rejected</option>
                        <option value="fulfilled">approved</option>
                        <option value="paid">paid</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='my-[10px]'>
                  {/* Check row lenght to 0  */}
                  {Array.isArray(expensesRow) && expensesRow.length > 0 ? (
                    <ExpensesTable
                      access={access}
                      expensesRow={filteredExpenses}
                      selected={selected}
                      setSelected={setSelected}
                      refreshExpenses={refreshExpenses}
                      selectedStatusFilter={selectedStatusFilter}
                    />
                  ) : (
                    <div className="user-count-view">No expenses found...</div>
                  )}
                </div>
              </div>
            </div>
            <Drawer
              anchor="right"
              open={showModalAdd}
              onClose={() => setShowModalAdd(false)}>
              <AddExpenses
                setShowModalAdd={setShowModalAdd}
                is24HourFormat={is24HourFormat}
                refreshExpenses={refreshExpenses}
              />
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
    </>
  )
}

export default Expenses
