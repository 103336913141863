import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  open: boolean;
  setOpen: any;
  refresh: any;
  selectedArrayId: any;
  setSelected: any;
  wordMenu: any;
}

const StatusArrayAll: React.FC<DeleteModalProps> = ({
  open,
  setOpen,
  refresh,
  selectedArrayId,
  setSelected,
  wordMenu,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = useState(false);
  const handleClose = () => setOpen(false);

  const handleChangeStatusAndClose = async () => {
    setActive(true);
    try {
      const deletePromises = selectedArrayId.map((id: any) => {
        return axios.put(`/expenses/${id}`, {
          status: wordMenu
        });
      }).filter((promise: any) => promise !== null);
      await Promise.all(deletePromises);
      enqueueSnackbar('Status change successfully', { variant: 'success' });
      setSelected([]);
    } catch (error: any) {
      if (error.response?.status === 500) {
        enqueueSnackbar('Error', { variant: 'error' });
      } else {
        console.error('Error', error);
        enqueueSnackbar('Error', { variant: 'error' });
      }
    } finally {
      setActive(false);
      handleClose();
      refresh();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="text-center">
              {`${t('AreYouSureChangeStatus')} ${selectedArrayId.length} ${t('StatusExp')} ${t('to')} ${wordMenu === 'fulfilled' ? 'approve' : wordMenu}?`}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              className="flex justify-between items-center gap-4">
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                disabled={active}
                onClick={handleChangeStatusAndClose}>
                {active ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('Yes')
                )}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                disabled={active}
                onClick={handleClose}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default StatusArrayAll;
