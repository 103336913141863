import { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import FinanceTable from './financeComponents/FinanceTable';
import Drawer from '@mui/material/Drawer';
import UserEditFinance from './modal/UserEditFinance';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  contract_start_date: Date;
  hoursPerWeek: number;
  contractType: {
    title: string
  }
}

interface OrdersProps {
  access: any,
  baseUrl: any
}

const Finance: React.FC<OrdersProps> = ({ access, baseUrl }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [users, setUsers] = useState<User[]>([]);
  const [show, setShow] = useState(false);
  const [contractType, setContractType] = useState<{ id: number; title: string }[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedContractTypeFilter, setSelectedContractTypeFilter] = useState<number | null>(null);
  const [originalUsers, setOriginalUsers] = useState<User[]>([]);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {       
        const { data } = await axios.get('user');
        const { data: usersData } = await axios.get('users/finance');
        const { data: contractType } = await axios.get('contract-type');
        if (data) {
          dispatch(setAuth(true));
        }
        setUsers(usersData);
        setContractType(contractType);
        setOriginalUsers(usersData);
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname]);

  const refreshUsers = async () => {
    try {
      const { data: usersData } = await axios.get('users/finance');
      setUsers(usersData);
      setOriginalUsers(usersData);
    } catch (error: any) {
      console.log('error users');
    }
  }

  const handleShow = (user: User) => {
    setSelectedUser(user);
    setShow(true);
  };

  return (
    <>
      {auth && access.finance_get > 0 ?
        (
          <>
            <div className="bg-[var(--bg-form)] w-full px-[15px] py-[10px] mb-[15px] rounded-[5px]">
              <div className='flex justify-between items-center'>
                <div className="flex gap-3 justify-start items-center w-full">
                  <input
                    //fillter users 
                    type="text"
                    className='w-[60%] text-[16px] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                    placeholder={t('fillter')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const searchText = e.target.value.toLowerCase();
                      const filteredUsers = originalUsers.filter(user =>
                        user.last_name.toLowerCase().includes(searchText) ||
                        user.first_name.toLowerCase().includes(searchText)
                      );
                      setUsers(filteredUsers);
                    }}
                  />
                </div>
                <div className='flex items-center justify-end gap-[10px] w-full'>
                  <p className='media-one text-[14px] font-semibold'>{t('SortBy')}:</p>
                  <select
                    className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                    value={selectedContractTypeFilter !== null ? selectedContractTypeFilter : 'all'}
                    onChange={(e) => setSelectedContractTypeFilter(e.target.value === 'all' ? null : Number(e.target.value))}>
                    <option value="all">all contract</option>
                    {contractType.map((cont) => (
                      <option key={cont.id} value={cont.id}>
                        {cont.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px] mb-3 relative pt-[55px]'>
              <div className='admin-box-users'>
                <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                  <div className="flex gap-4  my-[10px] justify-start items-center w-[80%]">
                    <h3 className='text-[18px]'>{t('UsersList')}:</h3>
                  </div>
                </div>
                <div className='my-[10px]'>
                  {Array.isArray(users) && users.length > 0 ? (
                    <FinanceTable
                      access={access}
                      users={users}
                      handleShow={handleShow}
                      contractType={contractType}
                      refreshUsers={refreshUsers}
                      selectedContractTypeFilter={selectedContractTypeFilter}
                      baseUrl={baseUrl}
                    />
                  ) : (
                    <div className="user-count-view">No users found...</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        {selectedUser &&
          <UserEditFinance
            selectedUser={selectedUser}
            refreshUsers={refreshUsers}
            setShow={setShow}
          />}
      </Drawer>
    </>
  )
}

export default Finance
