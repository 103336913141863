import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '@mui/material/TablePagination';
import { t } from 'i18next';
import moment from 'moment';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import DeleteArrayAll from '../modal/DeleteArrayAll';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

const StyledTableSort = styled(TableSortLabel)(({ theme, active, disabled }) => ({
  fontSize: 14,
  '& .MuiTableSortLabel-icon': {
    color: disabled && 'rgba(255, 255, 255, .3) !important',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '4px 10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '0 7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 13
}));

interface ReportsTableProps {
  equip: any;
  handleShow: any;
  access: any;
  sortDirection: any;
  status: any;
  refreshEquip: any;
  selectedStatusFilter: any;
  userID: any;
  selected: any;
  setSelected: any;
  setShowAproveEquipment: any;
}

const EquipmentTable: React.FC<ReportsTableProps> = ({
  equip,
  handleShow,
  access,
  userID,
  sortDirection,
  status,
  refreshEquip,
  selectedStatusFilter,
  selected,
  setSelected,
  setShowAproveEquipment }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDel, setOpenDel] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [orderBy, setOrderBy] = useState('inventory_id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleShowDelete = () => {
    setOpenDel(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenMenu = (event: any, userId: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedEquipId(userId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedEquipId(null);
  };

  const handleSelectStatus = async (statusId: any) => {
    try {
      const updatedSupervisorId = statusId === -1 ? null : statusId;
      await axios.put(`equipment/${selectedEquipId}`, { statusId: updatedSupervisorId });
      refreshEquip();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const handleRefreshPurch = async (equpId: any) => {
    try {
      const currentDate = new Date();
      await axios.put(`equipment/${equpId}`, {
        pat_date: currentDate,
        patUserId: userID
      });
      refreshEquip();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const handleRefreshInventory = async (equpId: any) => {
    try {
      const currentDate = new Date();
      await axios.put(`equipment/${equpId}`, {
        inventory_date: currentDate,
        inventoryUserId: userID
      });
      refreshEquip();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = equip.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedEquip = equip
    .filter((item: any) => selectedStatusFilter === null || item.statusId === selectedStatusFilter)
    .sort((a: any, b: any) => {
      const valueA = orderBy === 'inventory_id' ? a.inventory_id : a.item_name;
      const valueB = orderBy === 'inventory_id' ? b.inventory_id : b.item_name;
      if (order === 'asc') {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });

  useEffect(() => {
  }, [equip])

  const isSortingActive = sortDirection !== 'none';

  return (
    <>
      {equip?.length > 0 && (
        <div className='mb-[10px] text-center'>
          <div
            className={`flex items-center ${selected.length > 0 ? 'py-[5px] px-[10px]' : ''}`}
            style={{
              backgroundColor: selected.length > 0 ? 'rgba(25, 118, 210, 0.12)' : 'transparent',
            }}>
            {selected.length > 0 ? (
              <div
                className='flex justify-between w-full'>
                {selected.length} {t('Selected')}
              </div>
            ) : (<></>)}
            {selected.length > 0 && (
              <div className='flex gap-[20px] items-center'>
                <div className='w-full'>
                  <Button
                    color="success"
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    sx={{
                      padding: '4px 10px',
                      width: '150px',
                      fontSize: '10px'
                    }}
                    onClick={() => setShowAproveEquipment(true)}
                    disabled={selected.length === 0}>{t('Export')} selected</Button>
                </div>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                  onClick={() => handleShowDelete()} />
              </div>
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < equip.length}
                      checked={equip.length > 0 && selected.length === equip.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all equipment',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTableSort
                      active={orderBy === 'inventory_id'}
                      direction={orderBy === 'inventory_id' ? order : 'asc'}
                      disabled={isSortingActive}
                      onClick={() => handleRequestSort('inventory_id')}>
                      {t('ID')}
                    </StyledTableSort>
                  </StyledTableCell>
                  <StyledTableCell>{t("Item")}</StyledTableCell>
                  <StyledTableCell>{t("Manufacturer")}</StyledTableCell>
                  <StyledTableCell>{t("Model")}</StyledTableCell>
                  <StyledTableCell>{t("Category")}</StyledTableCell>
                  <StyledTableCell>{t("Location")}</StyledTableCell>
                  <StyledTableCell>{t("Responsive")}</StyledTableCell>
                  <StyledTableCell>{t("PurchaseDate")}</StyledTableCell>
                  <StyledTableCell>{t("InventoryDate")}</StyledTableCell>
                  <StyledTableCell>{t("InventoryUser")}</StyledTableCell>
                  <StyledTableCell>{t("Cost")}</StyledTableCell>
                  <StyledTableCell>{t("PATTestDue")}</StyledTableCell>
                  <StyledTableCell>{t("PATTestUser")}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t("Status")}</StyledTableCell>
                  <StyledTableCell align="right" width='5%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedEquip
                  ?.filter((user: any) => selectedStatusFilter === null || user.statusId === selectedStatusFilter)
                  .sort((a: any, b: any) => {
                    if (orderBy === 'inventory_id') {
                      if (order === 'asc') {
                        return a.inventory_id - b.inventory_id;
                      } else {
                        return b.inventory_id - a.inventory_id;
                      }
                    }
                    return 0;
                  })
                  .sort((a: any, b: any) => {
                    if (sortDirection === 'none') return 0;
                    const compareValueA = sortDirection === 'asc' ? a.item_name : b.item_name;
                    const compareValueB = sortDirection === 'asc' ? b.item_name : a.item_name;
                    return compareValueA.localeCompare(compareValueB);
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((equip: any, index: any) => (
                    <TableRow
                      key={equip.inventory_id || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.indexOf(equip.id) !== -1}
                          onChange={(event: any) => handleClick(event, equip.id)}
                        />
                      </StyledTableRow>
                      <StyledTableRow>{equip?.inventory_id}</StyledTableRow>
                      <StyledTableRow>{equip?.item_name}</StyledTableRow>
                      <StyledTableRow>{equip?.manufacturer}</StyledTableRow>
                      <StyledTableRow>{equip?.model}</StyledTableRow>
                      <StyledTableRow>{equip.categoryEquipment?.name}</StyledTableRow>
                      <StyledTableRow>{equip.location?.name}</StyledTableRow>
                      <StyledTableRow>{equip?.responsibleUserId && `${equip?.responsibleUser.first_name} ${equip?.responsibleUser.last_name}`}</StyledTableRow>
                      <StyledTableRow>{equip?.purchase_date && moment(equip?.purchase_date).format('DD.MM.YYYY')}</StyledTableRow>
                      <StyledTableRow>
                        <div className='flex justify-start items-center gap-[10px]'>
                          <button
                            onClick={() => handleRefreshInventory(equip.id)}
                            className='px-[4px] py-[2px] flex justify-center items-center bg-[var(--btn-user-in)] rounded-[5px]'>
                            <FontAwesomeIcon
                              className='text-[12px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                              icon={faArrowsRotate} />
                          </button>
                          {equip?.inventory_date && moment(equip.inventory_date).format('DD.MM.YYYY')}
                        </div>
                      </StyledTableRow>
                      <StyledTableRow>{equip?.inventoryUserId && `${equip?.inventoryUser.first_name} ${equip?.inventoryUser.last_name}`}</StyledTableRow>
                      <StyledTableRow>{equip?.cost}</StyledTableRow>
                      <StyledTableRow>
                        {equip?.pat_due > 0 &&
                          <div className='flex justify-start items-center gap-[10px]'>
                            <button
                              onClick={() => handleRefreshPurch(equip.id)}
                              className='px-[4px] py-[2px] flex justify-center items-center bg-[var(--btn-user-in)] rounded-[5px]'>
                              <FontAwesomeIcon
                                className='text-[12px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                icon={faArrowsRotate} />
                            </button>
                            {equip?.pat_date && moment(equip.pat_date).add(equip.pat_due, 'months').format('DD.MM.YYYY')}
                          </div>}
                      </StyledTableRow>
                      <StyledTableRow>{equip?.patUserId && `${equip?.patUser.first_name} ${equip?.patUser.last_name}`}</StyledTableRow>
                      <StyledTableRow
                        align="right"
                        onClick={(event) => handleOpenMenu(event, equip.id)}
                        className='cursor-pointer'>
                        {equip?.statusId ? equip?.status.name : 'none'}
                      </StyledTableRow>
                      <StyledTableRow align="right">
                        <div className='flex gap-[7px] justify-end items-center'>
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleShow(equip)} />
                        </div>
                      </StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={equip.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem
          key={-1}
          onClick={() => handleSelectStatus(-1)}>
          none
        </MenuItem>
        {status.map((status: any) => (
          <MenuItem
            key={status.id}
            onClick={() => handleSelectStatus(status.id)}>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
      {openDel && <DeleteArrayAll
        open={openDel}
        setOpen={setOpenDel}
        refresh={refreshEquip}
        selectedArrayId={selected}
        setSelected={setSelected}
        wordMenu={'equipments'}
        pd={'equipment'}
      />}
    </>
  );
};

export default EquipmentTable;