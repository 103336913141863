import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import { RootState } from '../../redux/store';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Tooltip from '@mui/material/Tooltip';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import {
  faLayerGroup,
  faCircleUp,
  faTable,
  faGear,
  faUserTie,
  faToolbox,
  faWindowRestore,
  faCoins,
  faDownload,
  faUserGroup,
  faCalendarCheck,
  faCommentDollar,
  faHashtag
} from '@fortawesome/free-solid-svg-icons';

interface NavProps {
  access: any
}

const Nav: React.FC<NavProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        dispatch(setAuth(false));
      }
    };
    if (auth) {
      fetchData();
    }
  }, [auth, dispatch]);

  const menuItems = [
    { name: "/timesheets", path: "/timesheets", label: "Timesheets", icon: faLayerGroup, accessKey: "timesheets_get", accessValue: 0, size: '>' },
    { name: "/approve", path: "/approve", label: "Approve", icon: faTable, accessKey: "approve_get", accessValue: 0, size: '>' },
    { name: "/reports", path: "/reports", label: "Reports", icon: faCircleUp, accessKey: "reports_get", accessValue: 0, size: '>' },
    { name: "/clients", path: "/clients", label: "Clients", icon: faUserTie, accessKey: "clients_get", accessValue: 0, size: '>' },
    { name: "/equipment", path: "/equipment", label: "Equipment", icon: faToolbox, accessKey: "equipment_get", accessValue: 0, size: '>' },
    { name: "/booking", path: "/booking", label: "Booking", icon: faWindowRestore, accessKey: "booking_get", accessValue: 0, size: '>' },
    { name: "/expenses", path: "/expenses", label: "Expenses", icon: faCommentDollar, accessKey: "expenses_get", accessValue: 0, size: '>' },
    { name: "/orders", path: "/orders", label: "Orders", icon: faDownload, accessKey: "orders_get", accessValue: 0, size: '>' },
    { name: "/settings", path: "/settings", label: "Settings", icon: faGear, accessKey: "settings", accessValue: 0, size: '>' },
    { name: "/users", path: "/users", label: "Users", icon: faUserGroup, accessKey: "users_get", accessValue: 0, size: '>' },
    { name: "/finance", path: "/finance", label: "Finance", icon: faCoins, accessKey: "finance_get", accessValue: 0, size: '>' },
    { name: "/toil", path: "/toil", label: "Toil", icon: faCalendarCheck, accessKey: "toil_get", accessValue: 0, size: '>' },
    { name: "/users-reports", path: "/users-reports", label: "UsersReports", icon: faHashtag, accessKey: "description", accessValue: -1, size: '===' },
  ];

  const isMenuActive = (path: any) => location.pathname === path;

  let li;

  if (auth && access) {
    li = (
      <ul className='flex gap-[5px] flex-col decoration-none normal-case list-none py-[2px]'>
        {menuItems
          .slice(0, 8)
          .map(({ name, path, label, icon, accessKey, accessValue, size }: any) => {
            let hasAccess = false;
            if (size === '>') {
              hasAccess = access[accessKey] > Number(accessValue);
            } else if (size === '<') {
              hasAccess = access[accessKey] < Number(accessValue);
            } else if (size === '===') {
              hasAccess = access[accessKey] === Number(accessValue);
            }
            return (
              hasAccess && (
                <Link
                  to={path}
                  key={name}
                  className={isMenuActive(path) ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
                  {/* <Tooltip title={label} placement="right"> */}
                  <li
                    className={`flex flex-col gap-[3px] text-center justify-between items-center cursor-pointer p-[var(--nav-pading)] text-[10px] hover:rounded hover:font-semibold hover:bg-[var(--btn-user)] group`}>
                    <FontAwesomeIcon
                      icon={icon}
                      className={`text-[20px] text-[var(--text-color)] transition-opacity duration-200 ${isMenuActive(path) ? 'opacity-90' : 'opacity-70 group-hover:opacity-90'}`} />
                    {t(label)}
                  </li>
                  {/* </Tooltip> */}
                </Link>
              )
            );
          })}
        <hr className='hr my-2' />
        {menuItems
          .slice(8)
          .map(({ name, path, label, icon, accessKey, accessValue, size }: any) => {
            let hasAccess = false;
            if (size === '>') {
              hasAccess = access[accessKey] > Number(accessValue);
            } else if (size === '<') {
              hasAccess = access[accessKey] < Number(accessValue);
            } else if (size === '===') {
              hasAccess = access[accessKey] === Number(accessValue);
            }
            return (
              hasAccess && (
                <Link
                  to={path}
                  key={name}
                  className={isMenuActive(path) ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
                  {/* <Tooltip title={label} placement="right"> */}
                  <li className='flex flex-col gap-[3px] text-center justify-between items-center cursor-pointer p-[var(--nav-pading)] text-[10px] hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
                    <FontAwesomeIcon icon={icon} className='opacity-70 text-[20px] text-[var(--text-color)]' />
                    {t(label)}
                  </li>
                  {/* </Tooltip> */}
                </Link>
              )
            );
          })}
      </ul>
    );
  } else {
    li = (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {auth && (
        <div className='nav-box left-0 top-[60px] z-50 overflow-hidden overflow-y-auto bg-[var(--bg-form)] w-[70px]'>
          <div className='flex flex-col item-center h-full w-full'>
            <div className="w-full ul-no-scroll">
              {li}
            </div>
          </div>
        </div >
      )}
    </>
  )
}

export default Nav