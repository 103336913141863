import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import UsersToils from './toilsComponents/UsersToils';
import Drawer from '@mui/material/Drawer';
import { useLocation } from "react-router-dom";
import UserToilsAdd from './modal/UserToilsAdd';
import UserToilsView from './modal/UserToilsView';
import { t } from 'i18next';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  email: string;
  date_of_birth: Date;
  contract_start_date: Date;
  contractType: {
    title: string
  };
  sex: string;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface UsersProps {
  access: any,
  baseUrl: string
}

const Toils: React.FC<UsersProps> = ({ access, baseUrl }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [originalUsers, setOriginalUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedUserToils, setSelectedUserToils] = useState<User | null>(null);
  const auth = useSelector((state: RootState) => state.auth.value);
  const [showModalToils, setShowModalToils] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [contract, setContract] = useState<{ id: number; title: string }[]>([]);
  const [selectedContractFilter, setSelectedContractFilter] = useState<number | null>(null);
  const [show, setShow] = useState(false);

  //open UserToilsView
  const handleShow = (user: User) => {
    setSelectedUser(user);
    setShow(true);
  };

  //open UserToils
  const handleShowToils = (user: User) => {
    setSelectedUserToils(user);
    setShowModalToils(true);
  };

  //refresh users
  const refreshToils = async () => {
    try {
      const { data: usersData } = await axios.get('users/toil');
      setUsers(usersData);
      setOriginalUsers(usersData);
    } catch (error: any) {
      console.log('error users');
    }
  }

  //get all data and check user
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('user');
        const { data: usersData } = await axios.get('users/toil');
        const { data: contractData } = await axios.get('contract-type');
        setUsers(usersData);
        setOriginalUsers(usersData);
        setContract(contractData);
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    })();
  }, [dispatch, location.pathname]);

  return (
    <>
      {auth && access.toil_get > 0 ? (
        <>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div className="flex gap-3 justify-start items-center w-full">
                <input
                  //fillter users 
                  type="text"
                  className='w-[60%] text-[16px] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                  placeholder={t('fillter')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const searchText = e.target.value.toLowerCase();
                    const filteredUsers = originalUsers.filter(user =>
                      user.last_name.toLowerCase().includes(searchText) ||
                      user.first_name.toLowerCase().includes(searchText)
                    );
                    setUsers(filteredUsers);
                  }} />
              </div>
              <div className='flex items-center justify-end gap-[10px] w-full'>
                <p className='media-one text-[14px] font-semibold'>{t('SortBy')}:</p>
                <select
                  // select contract
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  value={selectedContractFilter !== null ? selectedContractFilter : 'all'}
                  onChange={(e) => setSelectedContractFilter(e.target.value === 'all' ? null : Number(e.target.value))}>
                  <option value="all">all contract</option>
                  {contract.map((cont) => (
                    <option key={cont.id} value={cont.id}>
                      {cont.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px] mb-3 relative pt-[55px]'>
            <div className='admin-box-users'>
              <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                <div className="flex gap-4  my-[10px] justify-start items-center w-[80%]">
                  <h3 className='text-[18px]'>{t('UsersList')}:</h3>
                </div>
                <div className='flex gap-[40px] items-center'>
                  <div className="flex gap-[5px] mr-[10px] items-center text-[16px]">
                    <FontAwesomeIcon icon={faUserGroup} className='text-[16px] opacity-50 text-[var(--text-color)]' />
                    <div className="user-count-view"><span className='user-count'>{users?.length}</span> {t('users')}</div>
                  </div>
                </div>
              </div>
              <div className='my-[10px]'>
                {/* Check users lenght to 0  */}
                {Array.isArray(users) && users.length > 0 ? (
                  <UsersToils
                    access={access}
                    users={users}
                    handleShow={handleShow}
                    handleShowToils={handleShowToils}
                    selectedContractFilter={selectedContractFilter}
                    baseUrl={baseUrl}
                  />
                ) : (
                  <div className="user-count-view">No users found...</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        {selectedUser &&
          <UserToilsView
            selectedUser={selectedUser}
            refreshUsers={refreshToils}
          />}
      </Drawer>
      <Drawer
        anchor="right"
        open={showModalToils}
        onClose={() => setShowModalToils(false)}>
        <UserToilsAdd
          refreshUsers={refreshToils}
          setShowModalToils={setShowModalToils}
          selectedUserToils={selectedUserToils} />
      </Drawer>
    </>
  );
};

export default Toils;