import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import UsersToilsTable from '../toilsComponents/UsersToilsTable';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';

interface TemporaryDrawerProps {
  selectedUser: any;
  refreshUsers: any;
}

export default function UserToilsView({ selectedUser, refreshUsers }: TemporaryDrawerProps) {
  const [userInfo, setUserInfo] = useState(0);
  const [toils, setToils] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchToils = async () => {
    try {
      const { data: toilsUser } = await axios.get(`toils/user/${selectedUser.id}`);
      if (toilsUser) {
        toilsUser.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setToils(toilsUser);
      }
    } catch (error) {
      console.error('Error fetching toils:', error);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
          setUserInfo(data.roleId);
        }
        if (selectedUser) {
          const { data: toilsUser } = await axios.get(`toils/user/${selectedUser.id}`);
          toilsUser.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
          setToils(toilsUser);
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch, userInfo]);

  const refreshToils = async () => {
    if (selectedUser) {
      try {
        await axios.get(`toils/recountToils/${selectedUser.id}`);
        refreshUsers();
        enqueueSnackbar('Toils refreshed successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error refreshing toils:', error);
        enqueueSnackbar('Error refreshing toils', { variant: 'error' });
      } finally {
        fetchToils();
      }
    }
  };

  return (
    <Box sx={{
      width: 700,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[10px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold mb-[20px]'>{t('Toil')}</h1>
          <hr className='hr mt-1 mb-[10px]' />
          <UsersToilsTable toils={toils} />
          <hr className='hr mt-1 mb-[10px]' />
          <div>
            <Button
              variant="contained"
              endIcon={<Rotate90DegreesCwIcon
                sx={{ color: 'white !important' }} />}
              color="success"
              sx={{
                padding: '5px 10px',
                fontSize: 12,
                width: '160px',
                color: 'white !important'
              }}
              onClick={refreshToils}>
              {t('Refresh')} Toil
            </Button>
          </div>
        </div>
      </List>
    </Box>
  )
}