import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import { Dayjs } from 'dayjs';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import ReportsTable from './timesheetsComp/ReportsTable';
import Calendar from './timesheetsComp/Calendar';
import { transformToHierarchy } from './util/tree';
import TreeViewComponent from './util/TreeViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import enGB from 'date-fns/locale/en-GB';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import SelectTask from './timesheetsComp/SelectTask';
import TablePagination from '@mui/material/TablePagination';
import { useLocation } from "react-router-dom";
import AproveModal from './modal/AproveModal'
import { t } from 'i18next';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddTempToTimetheets from './modal/AddTempToTimetheets';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Template from './Template';

registerLocale('en-GB', enGB);

interface Report {
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  status: string;
}

interface TimeSheetsProps {
  access: any,
  is24HourFormat: boolean,
  taskOrTasks: any
}

interface Temp {
  temp_group_number: string;
  temp_name: string;
}

const Timesheets: React.FC<TimeSheetsProps> = ({ access, is24HourFormat, taskOrTasks }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [category, setCategory] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState('');
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersSuperId, setUsersSuperId] = useState('none');
  const [startDate, setStartDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(moment().hour(9).minute(0).toDate());
  const [timeTo, setTimeTo] = useState(moment().hour(9).minute(0).toDate());
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [description, setDescription] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [test, setTest] = useState<any>();
  const [week, setWeek] = useState<Dayjs[]>([]);
  const [dayPick, setDayPik] = useState<Dayjs | null>(null);;
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [perWeek, setPerWeek] = useState('');
  const location = useLocation();
  const [toils, setToils] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingWeek, setLoadingWeek] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [showAprove, setShowAprove] = useState(false);
  const [showReturnWeek, setReturnWeek] = useState(false);
  const [buttonAdd, setButtonAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [allTemplate, setAllTemplate] = useState<any[]>([]);
  const [selectedTemp, setSelectedTemp] = useState('');
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [allTemplateSelect, setAllTemplateSelect] = useState<Temp[]>([]);
  const [value, setValue] = useState('timesheets');
  const [selected, setSelected] = useState<readonly number[]>([]);
  let sum = 0;

  const transformedWeek = week.map((date: any) => moment(date.$d).format('DD.MM.YYYY'));

  const handleClick = () => {
    if (selectedIndex === -1) {
      return
    }
    setSelectedTemp(allTemplateSelect[selectedIndex].temp_group_number);
    setOpenAdd(true);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //show category
  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  //category select
  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      setTest(nodeId)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
    } else {
      console.log('Category not found');
    }
  };

  const refreshTempaleList = async () => {
    try {
      if (usersSuperId === 'none') {
        const { data } = await axios.get('template/user');
        const { data: template } = await axios.get('template/user/group');
        setAllTemplate(data);
        setAllTemplateSelect(template);
      } else {
        const { data } = await axios.post('template/user/sub', { userId: usersSuperId });
        const { data: template } = await axios.post('template/user/group/sub', { userId: usersSuperId });
        setAllTemplate(data);
        setAllTemplateSelect(template);
      }
    } catch (error: any) {
      console.log('error');
    }
  }

  //add date
  const handleDateChange = (date: Date) => {
    const newDate = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
    setStartDate(newDate);
  };

  //add time from
  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  //add time to
  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  //add timesheets
  const handleAddClick = async () => {
    const endTimeDate = timeTo < timeFrom ? moment(startDate).add(1, 'day').format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD');

    // Check if timeTo is equal to 00:00:00, and consider it as 24:00
    const isMidnight = moment(timeTo).format('HH:mm:ss') === "00:00:00";

    if ((moment(timeFrom).format('HH:mm:ss') >= moment(timeTo).format('HH:mm:ss')) && !isMidnight) {
      enqueueSnackbar('Invalid time range: TimeFrom must be less than TimeTo', { variant: 'error' });
      return;
    }

    if (selectedCategoryId.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (categoryName.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (selectedTasks.length === 0) {
      enqueueSnackbar('Invalid task: You did not select a task', { variant: 'error' });
      return;
    }
    if (!week.some(day => dayjs(day).isSame(startDate, 'day'))) {
      enqueueSnackbar('Invalid date: The selected date does not belong to the current week', { variant: 'error' });
      return;
    }

    // Convert timeFrom and timeTo to moment objects
    const newStartTime = moment(startDate).set({
      hours: moment(timeFrom).hours(),
      minutes: moment(timeFrom).minutes(),
      seconds: 0
    });

    // If the timeTo is 00:00:00, consider it as 24:00
    const newEndTime = isMidnight
      ? moment(startDate).add(1, 'day').startOf('day')
      : moment(startDate).set({
        hours: moment(timeTo).hours(),
        minutes: moment(timeTo).minutes(),
        seconds: moment(timeTo).seconds()
      });

    // Check for time overlap with existing reports
    const isTimeConflict = reports.some((report: any) => {
      const existingStartTime = moment(report.startTime).startOf('minute');
      const existingEndTime = moment(report.endTime).startOf('minute');
      const newStartTimeRounded = newStartTime.startOf('minute');
      const newEndTimeRounded = newEndTime.startOf('minute');

      // Check if newStartTime or newEndTime overlaps with existing report's time range
      return (
        moment(startDate).isSame(report.date, 'day') &&
        (
          (newStartTimeRounded.isBefore(existingEndTime) && newEndTimeRounded.isAfter(existingStartTime))
        )
      );
    });

    if (isTimeConflict) {
      enqueueSnackbar('Time conflict: The selected time is busy', { variant: 'error' });
      return;
    }

    let selectedTasksArray = [];
    if (selectedTasks) {
      selectedTasksArray = selectedTasks.map((option) => option.value);
    }
    setButtonAdd(true);
    try {
      await axios.post('/time-reports', {
        date: startDate,
        startTime: moment(startDate).format('YYYY-MM-DD') + ' ' + newStartTime.format('HH:mm:ss'),
        endTime: endTimeDate + ' ' + moment(timeTo).format('HH:mm:ss'),
        categoryId: test,
        description: description,
        tasksId: selectedTasksArray,
        ...(usersSuperId !== 'none' && { userId: usersSuperId })
      });
      updateReports();
      setDescription('');
      enqueueSnackbar('Report created successfully', { variant: 'success' });
    } catch (error: any) {
      console.error("Error adding timesheet:", error);
      enqueueSnackbar("Error adding timesheet", { variant: 'error' });
    }
  };

  const handleChangeAll = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //update reports function
  const updateReports = async () => {
    setLoading(true);
    try {
      let updatedReports;
      if (usersSuperId === 'none') {
        const { data } = await axios.get('/time-reports');
        updatedReports = data;
      } else {
        const { data } = await axios.post('/time-reports/reports/sub', { userId: usersSuperId });
        updatedReports = data;
      }
      setReports(updatedReports);
    } catch (error) {
      console.error("Error updating reports:", error);
    } finally {
      setLoading(false);
      setButtonAdd(false);
    }
  };

  //sort reports
  const sortedReports = reports?.slice().sort((a, b) => {
    const dateA = moment((a as Report).date);
    const dateB = moment((b as Report).date);
    if (dateA.isSame(dateB, 'day')) {
      const timeA = moment((a as Report).startTime).utcOffset((a as Report).startTime);
      const timeB = moment((b as Report).startTime).utcOffset((b as Report).startTime);
      return timeA.isBefore(timeB) ? -1 : timeA.isAfter(timeB) ? 1 : 0;
    } else {
      return dateA.isBefore(dateB) ? -1 : 1;
    }
  });

  //create rows from reports
  const rows = sortedReports?.filter((item: any) => {
    const itemDate = moment(item.date).format('DD.MM.YYYY');
    return transformedWeek.includes(itemDate)
  }).map((item: any) => {
    const startTime = moment(item.startTime);
    const endTime = moment(item.endTime);
    let duration = moment.duration(endTime.diff(startTime));
    if (duration.asMinutes() < 0) {
      duration = moment.duration(endTime.add(1, 'day').diff(startTime));
    }
    const diffInMinutes = endTime.diff(startTime, 'minutes');
    const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
    sum += roundedMinutes;
    return {
      id: item.id,
      date: item.date,
      startTime: item.startTime,
      endTime: item.endTime,
      categoryid: item.categoryId,
      description: item.description,
      dateId: item.id,
      status: item.status,
      tasks: item.tasks,
      supervisorId: item.supervisorId,
      supervisor: item.supervisor,
      categoryTree: `${item.categoryTree}`,
      supervisorFirstName: item.supervisor?.first_name,
      supervisorLastName: item.supervisor?.last_name,
      allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`
    };
  });

  // Grouping rows by date
  const groupedRows: { [key: string]: any[] } = {};
  rows.forEach(row => {
    const date = moment(row.date).format('YYYY-MM-DD');
    if (!groupedRows[date]) {
      groupedRows[date] = [];
    }
    groupedRows[date].push(row);
  });

  // We are checking whether the reports for this week are not in "pending, rejected, fulfilled" status
  const isStartDateInCurrentWeek = () => {
    const nonPendingReports = rows.some(report => {
      return dayjs(report.date) && (
        report.status === 'pending'
        || report.status === 'rejected'
        || report.status === 'fulfilled');
    });

    return !nonPendingReports;
  };

  //chacge reports status to peding
  const changeReportStatus = async (reportIds: any, statusReport: string) => {
    try {
      await axios.put('/time-reports/updateStatus', { reportsId: reportIds, status: statusReport });
      enqueueSnackbar('Reports successfully sent for review', { variant: 'success' });
    } catch (error: any) {
      if (statusReport !== 'new') {
        let errorMessage = 'Error changing status of reports';
        if (error.response) {
          const serverMessage = error.response?.data?.message;
          if (Array.isArray(serverMessage)) {
            errorMessage = serverMessage
              .map(
                (item: any, index: number) =>
                  `Conflict ${index + 1}:\n` +
                  `Interval 1: start ${new Date(item.interval1.start).toLocaleString()} , end ${new Date(item.interval1.end).toLocaleString()}\n` +
                  `Interval 2: start ${new Date(item.interval2.start).toLocaleString()} , end ${new Date(item.interval2.end).toLocaleString()}`
              )
              .join('\n\n');
          } else {
            errorMessage = serverMessage || `Error: ${error.response.statusText || 'Bad Request'}`;
          }
        } else if (error.message) {
          errorMessage = error.message;
        }
        enqueueSnackbar(
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{errorMessage}</pre>,
          {
            variant: 'error',
            autoHideDuration: 10000
          }
        );
        throw error;
      } else {
        enqueueSnackbar('Error back week', { variant: 'error' });
      }
    }
  };

  //sent to approve
  const sendToApprove = async () => {
    setLoading(true);
    setShowAprove(false);
    try {
      const reportIdsToChange = rows
        .filter(report => report.status !== 'fulfilled' && report.status !== 'pending')
        .map((report: any) => report.dateId);
      if (reportIdsToChange.length > 0) {
        await changeReportStatus(reportIdsToChange, 'pending');
      }
      if (usersSuperId === 'none') {
        const { data } = await axios.get('user');
        if (data) {
          setToils(data.totalToils);
        }
      } else {
        const { data } = await axios.post('user/sub', { userId: usersSuperId });
        if (data) {
          setToils(data.totalToils);
        }
      }
      updateReports();
    } catch (error) {
      console.error('Error sending reports for review:', error);
      enqueueSnackbar('Error sending reports for review', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  //return week
  const sendReturnWeek = async () => {
    setLoadingWeek(true);
    setReturnWeek(false);
    try {
      const reportIdsToChange = rows
        .map((report: any) => report.dateId);
      if (reportIdsToChange.length > 0) {
        await changeReportStatus(reportIdsToChange, 'new');
      }
      if (usersSuperId === 'none') {
        const { data } = await axios.get('user');
        if (data) {
          setToils(data.totalToils);
        }
      } else {
        const { data } = await axios.post('user/sub', { userId: usersSuperId });
        if (data) {
          setToils(data.totalToils);
        }
      }
      updateReports();
    } catch (error) {
      console.error('Error reeturn week:', error);
      enqueueSnackbar('Error reeturn week', { variant: 'error' });
    } finally {
      setLoadingWeek(false);
    }
  };

  //get all data and check user
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        let userData;
        if (usersSuperId === 'none') {
          const { data } = await axios.get('user');
          userData = data;
        } else {
          const { data } = await axios.post('user/sub', { userId: usersSuperId });
          userData = data;
        }
        if (userData) {
          setToils(userData.totalToils);
          setPerWeek(userData.hoursPerWeek);
        }
        const { data } = await axios.get('user');
        if (data) {
          setUserInfo(data.id);
        }
        const { data: categoryData } = await axios.get('category');
        const { data: superUsersData } = await axios.get('users');
        let reportsData;
        let templateData;
        if (usersSuperId === 'none') {
          const { data: reports } = await axios.get('/time-reports');
          const { data: template } = await axios.get('template/user/group');
          reportsData = reports;
          templateData = template;
        } else {
          const { data: reports } = await axios.post('/time-reports/reports/sub', { userId: usersSuperId });
          const { data: template } = await axios.post('template/user/group/sub', { userId: usersSuperId });
          reportsData = reports;
          templateData = template;
        }
        setAllTemplateSelect(templateData);
        setReports(reportsData);
        setUsers(superUsersData);
        setAllCategory(categoryData);
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
        dispatch(setAuth(true));
      } catch (e) {
        setUserInfo('You are not authenticated');
        dispatch(setAuth(false));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, transformToHierarchyCallback, location.pathname, usersSuperId]);

  const refreshSelectTemplate = async () => {
    try {
      let templateData;
      if (usersSuperId === 'none') {
        const { data: template } = await axios.get('template/user/group');
        templateData = template;
      } else {
        const { data: template } = await axios.post('template/user/group/sub', { userId: usersSuperId });
        templateData = template;
      }
      setAllTemplateSelect(templateData);
    } catch (error) {
      console.error('Error fetching template data:', error);
    }
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible]);

  const convertToHoursAndMinutes = (totalToils: any) => {
    const isNegative = totalToils < 0;
    const absoluteTotalToils = Math.abs(totalToils);
    const hours = Math.trunc(absoluteTotalToils);
    const minutes = Math.round((absoluteTotalToils - hours) * 60);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${isNegative ? '-' : ''}${hours}:${formattedMinutes}`;
  };

  useEffect(() => {
    if (dayPick) {
      const selectedDay = dayPick.toDate();
      const newDate = moment(selectedDay).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
      setStartDate(newDate)
    }
    // if (week.length > 0) {
    //   const currentWeekStart = moment().startOf('isoWeek').toDate();
    //   const isCurrentWeek = week.some(item => moment(item.toDate()).isSame(currentWeekStart, 'isoWeek'));
    //   if (isCurrentWeek) {
    //     setStartDate(new Date());
    //   } else {
    //     const firstDayOfWeek = moment(week[0].toDate()).startOf('isoWeek').toDate();
    //     setStartDate(firstDayOfWeek);
    //   }
    // }
  }, [week, userInfo, dayPick]);

  const fixPerWeek = Number(perWeek) * 60;

  return (
    <div className='mt-[-10px]'>
      <div className='absolute right-[20px]'>
        {usersSuperId !== 'none' &&
          users
            ?.filter((user: any) => user.id === Number(usersSuperId))
            .map((user: any) => (
              <div
                key={user.id}
                className='text-red-500 opacity-80 text-[14px]'>
                {t('You are currently under')}: {user.first_name} {user.last_name} {user.second_name}
              </div>
            ))}
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{
            borderBottom: 1,
            borderColor: "rgba(var(--shodow), 0.05)"
          }}>
            <TabList onChange={handleChangeAll}>
              <Tab label="Timesheets" value='timesheets' sx={{ color: 'var(--text-color)', fontSize: 12 }} />
              <Tab label="Templates" value='templates' sx={{ color: 'var(--text-color)', fontSize: 12 }} />
            </TabList>
          </Box>
          <TabPanel
            value='timesheets'
            sx={{ padding: '10px 0' }}>
            {auth && access.timesheets_get > 0 ? (
              <div>
                <div className='flex gap-[15px] media-flex-wrap w-full items-start'>
                  <div className='relative box-users flex flex-col justify-start p-[15px] rounded-[5px] pb-[65px] pt-[65px] min-w-[400px] flex-grow'>
                    <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                      <div className="flex gap-4 my-[10px] justify-start items-center">
                        <h3 className='text-[18px] media-one whitespace-nowrap'>{t('TimesheetsList')}:</h3>
                      </div>
                      {access.timesheets_get > 1 &&
                        <div className='w-full flex items-center justify-end gap-[7px]'>
                          <label className='text-[10px]'>{t('SelectUsers')}:</label>
                          <select
                            className="edit-select py-[4px] px-[10px] rounded-[5px] edit-input"
                            value={usersSuperId || 'none'}
                            onChange={(e) => {
                              setUsersSuperId(e.target.value);
                              setSelected([])
                            }}>
                            <option value="none">none</option>
                            {users
                              ?.filter((user: any) => user.id !== userInfo)
                              .sort((a: any, b: any) =>
                                a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name)
                              )
                              .map((user: any) => (
                                <option key={user.id} value={user.id}>
                                  {user.first_name} {user.last_name} {user.second_name}
                                </option>))}
                          </select>
                        </div>
                      }
                    </div>
                    {rows.length > 0 || !loading ? (
                      <ReportsTable
                        rows={rows}
                        updateReports={updateReports}
                        openCheckModal={openCheckModal}
                        setCheckOpenModal={setCheckOpenModal}
                        page={page}
                        loading={loading}
                        rowsPerPage={rowsPerPage}
                        selected={selected}
                        setSelected={setSelected}
                        taskOrTasks={taskOrTasks} />) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                      </Box>
                    )}
                    {rows.length > 0 &&
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        className='absolute bottom-[30px] left-[0]'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    }
                    <div className='absolute bottom-[10px] right-[18px] flex gap-[10px]'>
                      {rows.length > 0 &&
                        <>
                          {access.return_week === 1 &&
                            <Button
                              variant="contained"
                              endIcon={<AssignmentReturnIcon
                                sx={{ color: 'white !important' }} />}
                              sx={{
                                height: '30px',
                                fontSize: 14,
                                color: 'white !important',
                                whiteSpace: 'nowrap'
                              }}
                              onClick={() => !loadingWeek && setReturnWeek(true)}
                              disabled={loadingWeek}>
                              {loadingWeek ? (
                                <CircularProgress size={15} color="inherit" />
                              ) : (
                                t('ReturnWeek')
                              )}
                            </Button>
                          }
                        </>
                      }
                      <React.Fragment>
                        <ButtonGroup
                          variant="contained"
                          ref={anchorRef}
                          sx={{
                            height: '30px',
                            minWidth: '195px',
                            color: 'white !important'
                          }}
                          aria-label="Button group with a nested menu">
                          <Button
                            sx={{
                              width: '100%',
                              color: 'white !important',
                              whiteSpace: 'nowrap'
                            }}
                            onClick={handleClick}>
                            {allTemplateSelect[selectedIndex] ? allTemplateSelect[selectedIndex].temp_name : t('SelectTemplate')}
                          </Button>
                          <Button
                            size="small"
                            aria-label="select merge strategy"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="menu"
                            sx={{
                              color: 'white !important',
                              whiteSpace: 'nowrap'
                            }}
                            onClick={handleToggle}>
                            <ArrowDropDownIcon
                              sx={{
                                color: 'white !important'
                              }} />
                          </Button>
                        </ButtonGroup>
                        <Popper
                          sx={{ zIndex: 1 }}
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom' ? 'center top' : 'center bottom',
                              }}>
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu" autoFocusItem>
                                    {allTemplateSelect?.map((option, index) => (
                                      <MenuItem
                                        key={option.temp_group_number}
                                        selected={index === selectedIndex}
                                        value={option.temp_group_number}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                      >
                                        {option.temp_name}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </React.Fragment>
                      {rows.length > 0 &&
                        <Button
                          variant="contained"
                          endIcon={<NoteAddIcon
                            sx={{ color: 'white !important' }} />}
                          sx={{
                            height: '30px',
                            fontSize: 14,
                            color: 'white !important',
                            whiteSpace: 'nowrap'
                          }}
                          onClick={() => !loading && setShowAprove(true)}
                          disabled={loading}>
                          {loading ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            t('SendTo')
                          )}
                        </Button>
                      }
                    </div>
                  </div>
                  <div className='flex flex-col gap-[15px] flex-grow media-calendar filter-block'>
                    <div className="bg-[var(--bg-form)] w-full xl:max-w-[500px] flex justify-left items-center gap-[5px] p-[15px] rounded-[5px] flex-wrap relative pt-[65px] filter-block">
                      <div className='flex justify-between items-center absolute bg-[var(--bg-top)] top-0 w-full left-0 pr-[15px] pl-[20px] rounded-t-[5px]'>
                        <div className="flex gap-4 my-[10px] justify-start items-center w-[80%]">
                          <h3 className='text-[18px]'>{t('AddReport')}:</h3>
                        </div>
                      </div>
                      <div className='flex flex-col mb-[5px] w-full'>
                        <label className='text-[10px] ml-[5px]'>{t('Date')}:</label>
                        <div className='flex gap-[5px] w-full'>
                          <div className="flex w-full">
                            <DatePicker
                              selected={startDate}
                              onChange={(date: any) => handleDateChange(date)}
                              className="timesheets-input-all text-right text-[16px] full-int p-[5px] rounded-[5px]"
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-start"
                              locale="en-GB"
                              minDate={week.length > 0 ? week[0].toDate() : null}
                              maxDate={week.length > 0 ? week[week.length - 1].toDate() : null}
                            />
                          </div>
                          <div className='flex w-[180px]'>
                            <DatePicker
                              selected={timeFrom}
                              onChange={(time: any) => handleTimeFromChange(time)}
                              className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                              placeholderText="Select Time"
                              showTimeSelect
                              dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                              timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                              showTimeSelectOnly
                              timeIntervals={15} />
                          </div>
                          <div className='flex w-[180px]'>
                            <DatePicker
                              selected={timeTo}
                              onChange={(time: any) => handleTimeToChange(time)}
                              className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                              showTimeSelect
                              dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                              timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                              showTimeSelectOnly
                              timeIntervals={15} />
                          </div>
                        </div>
                      </div>
                      <div className='mb-[7px] flex flex-col w-full'>
                        <label className='text-[10px] ml-[5px]'>{t('Category')}:</label>
                        <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-full flex-grow text-[16px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
                          onClick={handleClickCategoryBox}>
                          <div className="width-fix">{categoryName.length ? categoryName : <div className='text-[#80807a] text-[16px]'>{t('SelectCategory')}</div>}</div>
                          <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50 ' /></div>
                          {isCategoryListVisible && (
                            <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] py-[5px]"
                              onClick={handleCategoryListClick}>
                              <TreeViewComponent
                                nodes={category}
                                onNodeSelect={handleNodeSelect}
                                updateTree={''}
                                openCheckModal={openCheckModal}
                                selectedCategoryId={''} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='flex flex-col mb-[5px] w-full'>
                        <label className='text-[10px] ml-[5px]'>{t('Task')}:</label>
                        <div
                          className='relative flex-grow'>
                          <SelectTask
                            test={test}
                            setSelectedTasks={setSelectedTasks}
                            selectedTasks={selectedTasks}
                            taskOrTasks={taskOrTasks} />
                        </div>
                      </div>
                      <div className='flex flex-col w-full'>
                        <label className='text-[10px] ml-[5px]'>{t('Description')}:</label>
                        <div
                          className='w-full relative flex-grow'>
                          <textarea
                            className='w-full input-timesheet text-[var(--text-color)] text-[16px] bg-[var(--bg-input)] p-[5px] rounded-[5px]'
                            rows={1}
                            value={description}
                            placeholder={t('DescriptionPoins')}
                            onChange={(e) => setDescription(e.target.value)} />
                        </div>
                      </div>
                      <hr className='hr mb-[10px] w-full' />
                      <div className='flex items-center justify-center flex-grow w-full'>
                        <Button
                          variant="contained"
                          endIcon={<PlaylistAddIcon
                            sx={{ color: 'white !important' }} />}
                          sx={{
                            padding: '5px 15px',
                            fontSize: 14,
                            color: 'white !important',
                            width: '100%'
                          }}
                          onClick={handleAddClick}
                          disabled={!isStartDateInCurrentWeek() || buttonAdd}>
                          {t('Add')}
                        </Button>
                      </div>
                    </div>
                    <div className="timesheet-calendar rounded-[5px] p-[10px] max-h-[350px] flex-grow">
                      <Calendar
                        setWeek={setWeek}
                        setDayPik={setDayPik} />
                    </div>
                    <div className="timesheet-box-right-bottom py-[15px] px-[25px] flex justify-between max-h-[150px] rounded-[5px]">
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('PerWee')}</h5>
                        <h1>{Math.floor(fixPerWeek / 60)}:{(fixPerWeek % 60).toFixed(0).padStart(2, '0')}hrs</h1>
                        {/* <p className='text-[14px]'>of 4 hrs weekly limit</p> */}
                      </div>
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('TOIL')}</h5>
                        <h1>{convertToHoursAndMinutes(toils)}hrs</h1>
                        {/* <p className='text-[14px]'>not approve {convertToHoursAndMinutes(pendingToils)}hrs</p> */}
                      </div>
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('ThisWeek')}</h5>
                        <h1>{Math.floor(sum / 60)}:{(sum % 60).toFixed(0).padStart(2, '0')}hrs</h1>
                        {/* <p className='text-[14px]'>of 4 hrs weekly limit</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {showAprove &&
                  <AproveModal
                    showAprove={showAprove}
                    setShowAprove={setShowAprove}
                    sendToApprove={sendToApprove}
                    setQuestion={'AreYouSureReport'} />}
                {showReturnWeek &&
                  <AproveModal
                    showAprove={showReturnWeek}
                    setShowAprove={setReturnWeek}
                    sendToApprove={sendReturnWeek}
                    setQuestion={'AreYouSureBackWeek'} />}
                {openAdd &&
                  <AddTempToTimetheets
                    rows={rows}
                    openAdd={openAdd}
                    setOpenAdd={setOpenAdd}
                    week={week}
                    updateReports={updateReports}
                    selectedTemp={selectedTemp}
                    setSelectedIndex={setSelectedIndex}
                    usersSuperId={usersSuperId} />}
              </div >
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value='templates'
            sx={{ padding: '10px 0' }}>
            <Template
              access={access}
              usersSuperId={usersSuperId}
              is24HourFormat={is24HourFormat}
              taskOrTasks={taskOrTasks}
              refreshSelectTemplate={refreshSelectTemplate}
              setAllTemplate={setAllTemplate}
              allTemplate={allTemplate}
              allTemplateSelect={allTemplateSelect}
              setAllTemplateSelect={setAllTemplateSelect}
              refresh={refreshTempaleList}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Timesheets;